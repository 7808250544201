import { ThresholdIndices } from './threshold-indices';

export enum pcl5Threshold {
  NONE = 0,
  GREEN = 1,
  YELLOW = 2,
  RED = 3
}

export const pcl5ThresholdMap = [
  {
    name: 'pcl5Short-overall', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 3 },
      { threshold: ThresholdIndices.RED,  minValue: 5 }
    ]
  },
  {
    name: 'pcl5-relive', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 3 }
    ]
  },
  {
    name: 'pcl5-avoid', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 3 }
    ]
  },
  {
    name: 'pcl5-distant', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 2 },
      { threshold: ThresholdIndices.RED,  minValue: 4 }
    ]
  },
  {
    name: 'pcl5-irritable', thresholds: [
    { threshold: ThresholdIndices.GREEN, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 2 },
    { threshold: ThresholdIndices.RED,  minValue: 4 }
  ]
}];
