import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocketIoModule, Socket } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggedInGuard, OfflineHandlingGuard } from '../../../../noctem-lib/src/lib/auth';
import { NetworkService } from '../../../../noctem-lib/src/lib/services/network-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { TranslationService, Translations, socketConfig, NoctemLibModule, ApplicationContext, CLIENT_ID, ModelFactory, useModelFactory, CacheService } from '../../../../noctem-lib/src/public-api';
import { environment } from '../environments/environment';
// import { WheelSelectorModule } from '@hyperblob/ngx-wheel-selector';
import { SleepRecommendationModalComponent } from './dashboard/sleep-recommendation-modal/sleep-recommendation-modal.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { HttpClient } from '@angular/common/http';
//import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Badge } from '@ionic-native/badge/ngx';
import { GtagModule } from 'angular-gtag';
import { CALC_CONFIG } from '../../../../noctem-lib/src/public-api';
import { CalculationConstants } from '../../../constant-definitions/constants';
import { AppNativeBus} from './app.bus'

import { BehaviorSubject } from 'rxjs';

// TODO: is there a better place for this? Perhaps constants?
import { APP_HOST } from '../../../../noctem-lib/src/core/web-ng';


export function getCacheService(httpClient: HttpClient) { return new CacheService(new ApplicationContext(httpClient)); }


socketConfig.url = environment.appSocketHost;
socketConfig.options.path = environment.appSocketPath || '/socket.io/';

const socket = new Socket(socketConfig)
const networkService = new NetworkService(socket);
networkService.initialize();

export function getNetworkService() {
  return networkService;
}

export function getApplicationContext(httpClient: HttpClient) { return new ApplicationContext(httpClient); }

@NgModule({
  declarations: [AppComponent],
  entryComponents: [SleepRecommendationModalComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    BrowserAnimationsModule,
    // WheelSelectorModule,
    NoctemLibModule,
    DashboardModule,
    ModalModule.forRoot(),
    GtagModule.forRoot({ trackingId: 'G-Q7MWRM1SHK', trackPageviews: false }),
  ],
  providers: [
    LoggedInGuard,
    OfflineHandlingGuard,
    { provide: CLIENT_ID, useValue: environment.organization },
    { provide: CALC_CONFIG, useValue: CalculationConstants },
    [{
      provide: ModelFactory,
      useFactory: useModelFactory
    }],
    StatusBar,
    SplashScreen,
    TranslationService,
    Translations,
    ApplicationContext,
    AppNativeBus,
    //QRScanner,
    Badge,
    { provide: APP_HOST, useValue: new BehaviorSubject(environment.appHost) },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: CacheService, useFactory: getCacheService },
    { provide: NetworkService, useFactory: getNetworkService }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
