import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { preFieldSurvey } from '../questions/training-survey/pre-field-survey';
import { preFieldSurveySoldier } from '../questions/training-survey/pre-field-survey-soldier';

export const preFieldSoldier: AssetDefinition = {
  id: 31,
  name: 'pre-field-soldier',
  label: 'Pre Training Soldier',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [preFieldSurvey, preFieldSurveySoldier],
  url: 'pre-field-soldier'
};
