import { ThresholdIndices } from './threshold-indices';

export const vactcsThresholdMap = [
  {
    name: 'vactcs', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 4 },
      { threshold: ThresholdIndices.RED,  minValue: 7 }
    ]
  }
];
