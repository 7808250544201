import { ThresholdIndices } from './threshold-indices';


export enum vacdsThreshold {
  NONE = 0,
  GREEN = 1,
  YELLOW = 2,
  RED = 3
}

export const vacdsThresholdMap = [
  {
    name: 'vacds', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 4 },
      { threshold: ThresholdIndices.RED,  minValue: 7 }
    ]
  }
];
