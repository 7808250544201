import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { trainingFeedbackSurvey } from '../questions/training-survey/training-feedback-survey';

export const trainingFeedbackSoldier: AssetDefinition = {
  id: 102, //was 10, conflict with sleep checkin
  name: 'training-feedback-soldier',
  label: 'Training feedback soldier',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [
    trainingFeedbackSurvey
  ],
  url: 'training-feedback-soldier'
};
