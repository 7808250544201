export class Dictionary {
  public static LogDate: 'log-date';
  public static SleepyFeels: 'sleepy-feels';
  public static UsedCaffeine: 'used-caffeine';
  public static PreventedSleep: 'prevented-sleep';
  public static SleptPast24: 'slept-past-24';
  public static TotalSleepPast24: 'total-sleep-past-24';
  public static DailySleepQuality: 'daily-sleep-quality';
  public static CaffeineSources: 'caffeine-sources';
  public static CaffeineAmount: 'caffeine-amount';
  public static StressStats: 'stress-stats';
  public static TeamStats: 'team-stats';

  // Calculated Values
  public static SleepIntensity: 'sleep-intensity';
  public static staticDreamRetraining: 'dream-retraining';


  // flags

  // modules
  public static Stress: 'stress';
  public static staticTeam: 'team';
}

