import { AssessmentDefinition } from "../../../../../noctem-lib/src/core/contracts/models.calculations";



export const sleepAids: AssessmentDefinition = {
  name: 'sleep-aids',
  description: 'Sleep Aids',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Sleep Aids',
      questionEmphasis: ``,
      subText: `<p>Sleep aids can be a quick, temporary fix for difficulties falling and staying asleep.</p>
                <p>Melatonin is a common over-the-counter option used for help initiating sleep and jet lag.</p>
                <p>A typical dose is typically between 0.5 – 3mg taken about 90 minutes before your planned bedtime.</p>
                <p>More is not better. Too high of a dose can lead to daytime sleepiness!</p>
      `,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'Sleep Aids',
      questionEmphasis: ``,
      subText: `<p>Other sleep aids include medications such as Ambien, Lunesta, and Sonata. These are called hypnotics. </p>
                <p>It is typically not recommended for these medications to be used unless you have a dedicated period of 7-8 hours of sleep. Less than that can result in grogginess as the medication wears off.</p>
                <p>Side effects can include headaches, feeling sluggish, and daytime drowsiness. Sleep walking and driving have also been reported.</p>
      `,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    }

  ]
};
