import { gad7ThresholdMap } from './gad7-thresholds';
import { isiThresholdMap } from './isi-thresholds';
import { pcl5ThresholdMap } from './pcl5-thresholds';
import { sleepCheckInMap } from './sleep-check-in-thresholds';
import { teamCommunicationThresholdMap } from './team-communication-thresholds';
import { vacdsThresholdMap } from './vacds-thresholds';
import { vactcsThresholdMap } from './vactcs-thresholds';


export const ThresholdDefinitions = [
  ...gad7ThresholdMap,
  ...isiThresholdMap,
  ...pcl5ThresholdMap,
  ...teamCommunicationThresholdMap,
  ...vacdsThresholdMap,
  ...vactcsThresholdMap,
  ...sleepCheckInMap
];
