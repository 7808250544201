import { NgModule, Optional, SkipSelf, APP_INITIALIZER, Injector, ModuleWithProviders } from '@angular/core';
import {
    AuthService, CalendarStateService, GameStateService, ChatStateService, PatientStateService,
    TreatmentPlanStateService, Translations, LogStateService, UserStateService, ApplicationStateService, LoggedInGuard,
    NotificationStateService, ApplicationContext, TranslationService, USER_STATE_SERVICE, ANON_TOKEN, CALC_CONFIG
} from '../../../../../noctem-lib/src/public-api';
import { LogService, AssessmentTemplateService, EmailMessageService, SocketService,
    MessageService, CalendarEventService, ExtraMetaService, socketConfig } from '@noctem/web';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { throwIfAlreadyLoaded } from "../../../../clinician-portal/src/app/core/module-import-guard";
import { initFactory } from './initFactory';
import { ServiceBus } from './service-bus';
import {CalculationConstants} from '../../../../constant-definitions/constants';
import { SocketIoModule, Socket } from 'ngx-socket-io';
import { CacheService } from '../../../../../noctem-lib/src/lib/services/cache-service';
import { DashboardStateCacheDecorator } from '../../../../../noctem-lib/src/lib/state/dashboard-state-cache.decorator';
import { DashboardStateService } from '../../../../../noctem-lib/src/lib/state/dashboard-state.service';
import { LogCacheServiceInterface } from '../../../../../noctem-lib/src/lib/state/log-cache-service.interface';
import { LogCacheService } from '../logs/log-cache-service';
import { LogSerializer } from '../../../../../noctem-lib/src/lib/state/log-serializer';

export function getTranslationsService(appContext: ApplicationContext) {
    return new TranslationService(appContext, new Translations());
}

export function getMessageService(appContext: ApplicationContext) { return new MessageService(appContext); }

export function getSocket() { return new Socket(socketConfig); }

export function getCacheService() { return new CacheService(true) }

export class NotLoggedInGuard {

}

@NgModule({
    imports: [
        HttpClientModule,
        SocketIoModule.forRoot(socketConfig)
    ],
    declarations: [
    ],
    providers: [
        UserStateService,
        SocketService,
        { provide: 'ngxSocket', useFactory: getSocket },
    ],
    exports: [
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [
                ApplicationStateService,
                ApplicationContext,
                AuthService,
                CalendarStateService,
                ChatStateService,
                PatientStateService,
                LogStateService,
                LogService,
                AssessmentTemplateService,
                GameStateService,
                NotificationStateService,
                EmailMessageService,
                ServiceBus,
                TreatmentPlanStateService,
                CalendarEventService,
                ExtraMetaService,
                LoggedInGuard,
                NotLoggedInGuard,
                LogSerializer,
                { provide: ANON_TOKEN, useValue: environment.token },
                { provide: TranslationService, useFactory: getTranslationsService, deps: [ApplicationContext] },
                { provide: MessageService, useFactory: getMessageService, deps: [ApplicationContext] },
                { provide: USER_STATE_SERVICE, useClass: UserStateService },
                { provide: CacheService, useFactory: getCacheService, deps: [] },
                { provide: DashboardStateService, useClass: DashboardStateCacheDecorator },
                { provide: LogCacheServiceInterface, useClass: LogCacheService },
                {
                    provide: APP_INITIALIZER,
                    useFactory: initFactory,
                    deps: [Injector],
                    multi: true
                },
                { provide: CALC_CONFIG, useValue: CalculationConstants }
            ]
        };
    }
}
