import { Notification, SimpleInstance, NotificationTypes } from '../../../../noctem-lib/src/lib/services/models';
import { Subject } from 'rxjs';
import { NotificationService, UserFuncService } from '../services/services';
import { BaseStateService } from './base-state.service';
import { uniqBy as _uniqBy, filter as _filter } from 'lodash';
import { TranslationService } from '../translations';
import { ApplicationContext, ModelFactory } from '../../core/web-ng';
import { Injectable, Output, EventEmitter } from "@angular/core";
import { CacheService } from '../../../../noctem-lib/src/lib/services/cache-service';
import { NetworkService } from '../../../../noctem-lib/src/lib/services/network-service';
import * as _ from 'lodash';

export class NotificationState {
    notifications: Notification[];
    patient: SimpleInstance;
    medicPatientIds: string[];
}

const SEND_NOTIFICATIONS_KEY    = 'MEDIC:SendNotifications';
const OFFLINE_NOTIFICATIONS_KEY = 'SOLDIER:OfflineNotifications';
const GET_ALL_ASYNC_NOTIFICATIONS_KEY = 'getAllAsync:Notification';
var notificationsToCache = [];

@Injectable({
    providedIn: 'root'
})
export class NotificationStateService extends BaseStateService<NotificationState> {
    public onNotificationAdded$: Subject<NotificationState> = new Subject();
    public earnedPointsRead$: Subject<boolean> = new Subject<boolean>();
    public sleepRecommendationRead$: Subject<boolean> = new Subject<boolean>();
    public notificationTemplates = [
        {
            type: NotificationTypes.earnedPoints,
            text: 'Total Point Update',
            subtext: 'Great Effort. You have earned {var1} total points.',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/coins'
            }
        },
        {
            type: NotificationTypes.newChatMessage,
            text: 'Read Message from your Provider',
            subtext: 'You have received a message. Please review it as soon as possible.',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/chat'
            }
        },
        {
            type: NotificationTypes.activeMorningLog,
            text: 'Complete Morning Log',
            subtext: 'Please submit your morning log.',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/logs'
            }
        },
        {
            type: NotificationTypes.activeEveningLog,
            text: 'Complete Evening Log',
            subtext: 'Please submit your evening log.',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/logs'
            }
        },
        {
            type: NotificationTypes.lateLog,
            text: 'You have a new sleep log to complete',
            subtext: 'Please enter your log as soon as you can!',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/logs/week'
            }
        },
        {
            type: NotificationTypes.checkinNeedsCompleted,
            text: '',
            subtext: '',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/logs/assessment'
            }
        },
        {
            type: NotificationTypes.sleepRecommendation,
            text: 'Follow Sleep Recommendation',
            subtext: 'You have received a new sleep recommendation. Please review and message your coach with any questions.',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/'
            }
        },
        {
            type: NotificationTypes.newCoin,
            text: 'New Coin Earned',
            subtext: 'Congratulations. You have earned the {var1} coin.',
            needsPersisted: false,
            onClick: {
                type: 'navigate',
                url: '/coins'
            }
        },
        {
            type: NotificationTypes.assessmentAvailable,
            text: 'Complete Assessment',
            subtext: 'Your assessment is ready to be completed. Please make sure it is submitted.',
            needsPersisted: true,
            onClick: {
                type: 'navigate',
                url: '/'
            }
        },
        {
            type: NotificationTypes.moduleMessage,
            text: 'Module Message',
            subtext: 'Medic message for {var1}',
            needsPersisted: true,
            onClick: {
                type: 'navigate',
                url: '/module'
            }
        },

    ];

    constructor(translationService: TranslationService,
        stateFactory: ModelFactory<NotificationState>,
        private notificationService: NotificationService,
        private userService: UserFuncService,
        private networkService: NetworkService,
        private cacheService: CacheService,
        private appContext: ApplicationContext) {
        super(new NotificationState(), stateFactory, translationService);
    }

    async initialize(currentUser: SimpleInstance) {
        const state = this.getState();
        state.patient = currentUser;
        const soldierQuery = {
            'Payload.profile.assignedProviders.id': currentUser?.id
        };
        this.userService.getAllAsync(soldierQuery).then(soldiers => {
            if(soldiers) {
                state.medicPatientIds = soldiers.map(s => {return s.UserId});
            }
        });
        this.notificationService.getAllAsync({$and: [{ 'Payload.patient.id': currentUser?.id }, {'Payload.isRead': false}, {'Payload.type': NotificationTypes.newChatMessage }]}).then(notifications => {
            const unreadChatNotfications = _filter(notifications, n => {
                return n.type === NotificationTypes.newChatMessage && !n.isRead;
            });

            if (notifications) {
                notifications = this.sortAndFilterNotifications(notifications);
                notifications.push(...unreadChatNotfications);
            }
            state.notifications = notifications;
            this.setState(state);
        });
        if (this.networkService.isOnline() && this.cacheService.get(SEND_NOTIFICATIONS_KEY)) {
            this.submitCachedNotifications();
        }
        this.networkService.onConnectionChange$.subscribe(connection => {
            if (this.networkService.isOnline()) {
                // When network is online, submit cached notifications
                this.submitCachedNotifications();
            }
        })
    }

    refresh() {
    }

    private sortAndFilterNotifications(notifications: Array<Notification>) {
        notifications = notifications?.sort((a, b) => (a.createdOn > b.createdOn) ? 1 : -1);
        notifications = notifications?.filter(n => n.type !== "newChatMessage");
        notifications = _uniqBy(notifications, notification => notification.type);
        return notifications;
    }

    // TODO: this may be defunct now. Haven't checked notifications for coins
    async markAsRead() {
        const state = this.getState();
        const unreadNotifications = _filter(state.notifications,notification => !notification.isRead);
        for (const notification of unreadNotifications) {
            if (notification.type === NotificationTypes.earnedPoints) {
                this.earnedPointsRead$.next(true);
            } else if (notification.type === NotificationTypes.sleepRecommendation) {
                this.sleepRecommendationRead$.next(true);
            }
        }

        let persistedNotifications = state.notifications?.filter(notification => (notification.type === NotificationTypes.newChatMessage ||
                                                                                    notification.type === NotificationTypes.assessmentAvailable) &&
                                                                                !notification.isRead);
        persistedNotifications?.map(notification => {
            notification.isRead = true;
        });
        for (const notification of persistedNotifications) {
            await this.notificationService.saveAsync(notification);
        }
    }

    async markNotificationRead(notification: Notification) {
        notification.isRead = true;
        let offlineNotifications: Array<Notification> = [];
        // If online save notification to the database
        if (this.networkService.isOnline()) {
            await this.notificationService.saveAsync(notification);
        } else {
            // If offline save and sync local cache
            offlineNotifications = this.cacheService.get(OFFLINE_NOTIFICATIONS_KEY);
            if (offlineNotifications?.length > 0) {
                const offlineNotificationsParsed: Array<Notification> = [];
                offlineNotifications.forEach(notif => {
                  // If the notif has the same Id update it to read
                  if (notif.Id === notification.Id) {
                    notif.isRead = true;
                    offlineNotificationsParsed.push(notif);
                  } else {
                    offlineNotificationsParsed.push(notif);
                  }
                })
                this.cacheService.set(OFFLINE_NOTIFICATIONS_KEY, offlineNotificationsParsed);
            } else {
              offlineNotifications.push(notification);
              this.cacheService.set(OFFLINE_NOTIFICATIONS_KEY, offlineNotifications);
            }
        }
    }

    markStateAsRead() {
        const state = this.getState();
        state.notifications = state.notifications?.map(notification => {
            notification.isRead = true;
            return notification;
        });
        this.setState(state);
    }

    async addModuleNotifications(type: string, userIds?: Array<string>,textInsert?: string, isRead?: boolean, url?: string) {
       userIds.forEach((patientId, index) => {
            this.add(type, textInsert, isRead, patientId, url);
        })
    }

    clearNotificationForUser(patientId, url) {
        if (!this.networkService.isOnline()) {
            const state = this.getState();
            if (notificationsToCache) {
                notificationsToCache = notificationsToCache.filter(notif => {
                    return !(notif.patient.id.endsWith(patientId) && url === notif.url)
                })
            }
            this.cacheService.set(SEND_NOTIFICATIONS_KEY, notificationsToCache);
            state.notifications = notificationsToCache;
            state.notifications = this.sortAndFilterNotifications(state.notifications);

            this.setState(state);
        }
    }

    // TODO: these notifications created on patient loading app?
    async add(type: string, textInsert?: string, isRead?: boolean, patientId?: string, url?: string) {
        const state = this.getState();
        const notification = this.createNotification(type, textInsert, isRead, patientId, url, false)

        if (this.networkService.isOnline()) {
            this.notificationService.saveAsync(notification).then(not => {
                state.notifications.push(not);
            });
        } else if (this.networkService.isOnline() === false) { // Cache the notification
            let cachedNotifications = this.cacheService.get(SEND_NOTIFICATIONS_KEY);
            if (!cachedNotifications) {
                cachedNotifications = {};
            }
            notificationsToCache.push(notification)
            this.cacheService.set(SEND_NOTIFICATIONS_KEY, notificationsToCache);
            state.notifications.push(notification);
        }
        const unreadChatNotfications = _filter(state.notifications, n => {
            return n.type === NotificationTypes.newChatMessage && !n.isRead;
        });
        state.notifications = this.sortAndFilterNotifications(state.notifications);
        state.notifications.push(...unreadChatNotfications);
        this.setState(state);
        this.onNotificationAdded$.next(state);
    }

    async checkForNotification(logType: string) {
        const date = new Date();
        const from = new Date(date.getFullYear(), date.getMonth(), date.getDay()+2, 4); // TODO: get rid of the +2. just for testing
        const to = new Date(date.getFullYear(), date.getMonth(), date.getDay()+2, 23, 59);
        const patient = this.getState().patient;
        if(!patient) {
          return false;
        }
        const notificationsInRange = this.notificationService.getAllAsync({
            $and: [
              {'Payload.patient.id': this.getState().patient.id},
              {'Payload.type': logType},
              {'Payload.createdOn': {$gt: from, $lt: to}},
            ]
          });
        if (notificationsInRange != null && (await notificationsInRange).length > 0) {
            // There was previously a notification for this log saved
            return true;
        }
        // There wasn't a notification saved for this day's log
        return false;
    }

    // Get notifications to display. Only those that are unread
    async getNotifications() {
      if (!this.appContext.User?.UserId){
          return [];
      }

      let unreadNotifications: Array<Notification> = [];
      let offlineNotifications: Array<Notification> = [];
      // If online get notifications from DB. Should be an array
      if (this.networkService.isOnline()) {
        unreadNotifications = await this.notificationService.getAllAsync({
          $and: [
            {'Payload.patient.id': this.appContext.User.UserId},
            {'Payload.isRead': false},
          ]
        });

        if(unreadNotifications) {
          unreadNotifications.forEach((notif, index) => {
              // If notification exists already offline, then dont add it
              const sameNotificationTypeIsLocal: Notification = this.cacheService.get(OFFLINE_NOTIFICATIONS_KEY)?.find(localNotif => localNotif.Id === notif.Id);
              if(sameNotificationTypeIsLocal?.isRead) {
                  notif.isRead = true;
                  this.notificationService.saveAsync(notif);
              }
          });
        }

        if(!unreadNotifications) {
          unreadNotifications = [];
        }
      } else {
        // If in offline mode get the notifications from local cache
        offlineNotifications = this.cacheService.get(OFFLINE_NOTIFICATIONS_KEY);
        if (offlineNotifications?.length > 0) {
          const offlineNotificationsGetAllAysnc: Array<Notification> = this.cacheService.get(GET_ALL_ASYNC_NOTIFICATIONS_KEY);
          offlineNotificationsGetAllAysnc.forEach(notif => {
            // If the notification exists in getAllAsync(notifications received while online) cache and it's unread add it to local cache
            const existsInLocalCache = offlineNotifications.find(localNotif => localNotif.Id === notif.Id);
            if(!existsInLocalCache && !notif.isRead) {
              offlineNotifications.push(notif);
              // Sync notifications online with the local cache
              this.cacheService.set(OFFLINE_NOTIFICATIONS_KEY, offlineNotifications);
            }
          });
          unreadNotifications = offlineNotifications.filter(notif => !notif?.isRead);
        } else {
          offlineNotifications = this.cacheService.get(GET_ALL_ASYNC_NOTIFICATIONS_KEY);
          this.cacheService.set(OFFLINE_NOTIFICATIONS_KEY, offlineNotifications);
          unreadNotifications = offlineNotifications.filter(notif => !notif?.isRead);
        }
      }
      return unreadNotifications;
    }

    // Network is online. Submit cached notifications.
    submitCachedNotifications() {
        const state = this.getState();
        const cachedNotifications = this.cacheService.get(SEND_NOTIFICATIONS_KEY);
        if (cachedNotifications) {
            cachedNotifications.forEach(notification => {
                const newNotification = this.createNotification(notification.type, notification.subtext, notification.isRead, notification.patient.id, notification.url, true);
                this.notificationService.saveAsync(newNotification).then(not => {
                    state.notifications.push(not);
                });
            });
        }
        this.cacheService.delete(SEND_NOTIFICATIONS_KEY);
        this.setState(state);
    }

    createNotification(type: string, textInsert: string, isRead: boolean, patientId: string, url: string, wasCached: boolean) {
        const state = this.getState();
        const notificationTemplate = this.notificationTemplates.find(n => n.type === type);
        const notification = new Notification();
        notification.type = type;
        notification.patient = _.cloneDeep(state.patient);
        notification.patient.id = patientId;
        notification.isRead = !!isRead;
        notification.createdOn = new Date().toISOString();
        notification.text = notificationTemplate.text;
        notification.url = url
        let subtext = notificationTemplate.subtext;
        if (textInsert) {
            subtext = wasCached ? textInsert : subtext.replace('{var1}', textInsert);
        }
        notification.subtext = subtext;
        return notification;
    }

    addLocalSoldierModuleNotification(text, subtext, moduleName) {
        let notification = {
            Id: null,
            type: NotificationTypes.moduleMessage,
            isRead: false,
            createdOn: new Date(),
            text: text,
            url: moduleName,
            subtext: subtext,
            offline: true
        }
        let offlineNotifications = this.cacheService.get(OFFLINE_NOTIFICATIONS_KEY);
        if (!offlineNotifications) {
            offlineNotifications = [];
        }
        offlineNotifications.push(notification)
        this.cacheService.set(OFFLINE_NOTIFICATIONS_KEY, offlineNotifications);
    }

    markLocalSoldierModuleRead(moduleName) {
        let offlineNotifications = this.cacheService.get(OFFLINE_NOTIFICATIONS_KEY);
        if (!offlineNotifications) {
            offlineNotifications = [];
        }
        const offlineNotificationsNotRead = offlineNotifications.filter(notif => notif.url !== moduleName);
        this.cacheService.set(OFFLINE_NOTIFICATIONS_KEY, offlineNotificationsNotRead);
    }
}
