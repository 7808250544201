import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UserStateService } from '../../../../../../noctem-lib/src/lib/state/user-state';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { USER_STATE_SERVICE, NoctemUser, Group } from '@noctem/web';
import { APPLICATION_ORGANIZATION } from '../../../../../../noctem-lib/src/constants/constants';
import { GroupService } from '../../../../../../noctem-lib/src/lib/services';
import { userAgreements } from '../../../../../../noctem-lib/src/constants/definitions/user-agreements';

@Component({
  selector: 'consent-page',
  templateUrl: './consent-page.component.html',
  styleUrls: []
})
export class ConsentPageComponent implements OnInit, OnDestroy {
  public template:any;
  public userResponses:Array<any> = [];
  private user = null;
  public hasConsent:boolean = true;
  private isResearch:boolean = false;
  private isConsentRequired:boolean = false;
  private userGrp:Group;
  public context:string = '';
  private logo:string = 'irb.png';
  isMAMC: boolean;
  destroy$: Subject<any> = new Subject();

  constructor(@Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
              private groupService: GroupService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.activatedRoute.params.pipe(
      takeUntil(this.destroy$)
      ).subscribe((params: Params) => {
      this.context = params['context'];

      this.userStateService.state$.pipe(
        filter((userState: any) => userState.User),
        takeUntil(this.destroy$)
      ).subscribe((userState) => {
        if(this.context === 'consent') {
          this.hasConsent = (userState.User.applicationData && userState.User.applicationData.consentObtainedOn);
        }
        else{
          this.hasConsent = (userState.User.applicationData && userState.User.applicationData.eulaAgreedOn);
        }
        this.user =  userState.User as any as NoctemUser;
        const group = userState.User.groups.find(g => g.id !== APPLICATION_ORGANIZATION._id);
        this.groupService.getAllAsync().then(grps => {
          const userGroup = this.user.groups.find(ug => ug.id !== APPLICATION_ORGANIZATION._id);
          this.userGrp = grps.find(g => g.__i.guid === userGroup.id);
          if(this.context === 'consent') {
            this.template = userAgreements.PatientConsents.find(d => d.id === this.userGrp.consentId ?? 0);
          }
          else {
            this.template = userAgreements.PatientEULAs.find(d => d.id === this.userGrp.eulaId ?? 0);
            this.isResearch = grps.find(g => g.__i.guid === group.id)?.isResearch;
            this.isConsentRequired = grps.find(g => g.__i.guid === group.id)?.isConsentRequired;
          }
          this.template.agreementQuestions.forEach(q => {
            this.userResponses[q.answerId] = this.hasConsent;
          });

          (this.template.id === 3 || this.template.id === 4 || this.template.id === 5)  ? this.isMAMC = true : this.isMAMC = false;
        });
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public checkAllAgreed():boolean {
    let returnValue: boolean = true;
    this.template?.agreementQuestions.forEach(r => {
      if(!this.userResponses[r.answerId])
        returnValue = false;
        return;
    });
    return returnValue;
  }

  onConsent() {

      if (!this.user.applicationData) {
        this.user.applicationData = {};
      }
      if(this.context === 'consent')
      {
        if (!this.user.applicationData.consentObtainedOn) {
          this.user.applicationData.consentObtainedOn = new Date().toISOString();
          this.userStateService.save(this.user).pipe(
            takeUntil(this.destroy$)
            ).subscribe(async user => {
            this.router.navigateByUrl('/');
          });
        } else {
          this.router.navigateByUrl('/');
        }
      }
      else{
        const forwardLink = ((this.isResearch || this.isConsentRequired) ? '/info/consent/' + (this.userGrp.consentId ?? 0).toString() : '/');
        if (!this.user.applicationData.eulaAgreedOn) {
          this.user.applicationData.eulaAgreedOn = new Date().toISOString();
          this.userStateService.save(this.user).pipe(
            takeUntil(this.destroy$)
            ).subscribe(async user => {
            this.router.navigateByUrl(forwardLink);
          });
        } else {
          this.router.navigateByUrl(forwardLink);
        }
      }


  }

}
