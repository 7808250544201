import { Injectable, Inject } from '@angular/core';
import { BaseStateService } from './base-state.service';
import { coinDefinitions } from '../../constants/constants';
import { UserStateService } from './user-state';
import { maxBy as _maxBy } from 'lodash';
import { Subject } from 'rxjs';
import { ExtraMetaService } from '../services/services';
import { ExtraMeta, AssessmentTemplate, AssessmentQuestion, NoctemUser } from '../services/models';
import { DayLog } from './log-state.service';
import { ApplicationContext, ModelFactory, USER_STATE_SERVICE } from '../../core/web-ng';
import { TranslationService } from '../translations';

export class GameState {
  isLoading: boolean;
  patientCoinDefinitions: Array<PatientCoin>;
  patientPoints: number;
  isModalDisplayed: boolean;
}

export class PatientCoin {
  name: string;
  displayName?: string;
  label: string;
  pointLowerLimit: number;
  pointUpperLimit: number;
  stageName: string;
  activeImagePath: string;
  inactiveImagePath: string;
  imagePathTails: string;
  isActive: boolean;
  status?: string;
  patientsWithCoin?: number;
  activeCoinDescriptions: any[];
  inactiveCoinDescriptions: any[];
}

@Injectable()
export class GameStateService extends BaseStateService<GameState> {
  public onCoinAchieved$: Subject<PatientCoin> = new Subject<PatientCoin>();

  constructor(
    translationService: TranslationService,
    stateFactory: ModelFactory<GameState>,
    private extraMetaService: ExtraMetaService,
    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
    private applicationContext: ApplicationContext
  ) {
    super(new GameState(), stateFactory, translationService);
  }

  public async initialize(user: NoctemUser) {
    const state = this.getState();
    if (user?.applicationData?.pointsAccumulated) {
      state.patientPoints = user.applicationData.pointsAccumulated;
      await this.setCoinDefinitions(state);
    } else {
      state.patientPoints = 0;
      await this.setCoinDefinitions(state);
    }
    state.isModalDisplayed = false;
  }

  public async setCoinDefinitions(state?: GameState) {
    if (!state) {
      state = this.getState();
    }

    let patientsWithCoins: any = {};
    try {
      patientsWithCoins = await this.extraMetaService.getOneAsync({
        'Payload.name': 'coinAchievements'
      });
    } catch (error) {
      console.error(`Game State Service failed to set coin definitions: ${error}`);
    }

    const definitions: Array<PatientCoin> = coinDefinitions.filter(coin => coin.isActive);
    for (let i = 0; i < definitions.length; i++) {
      definitions[i].patientsWithCoin = patientsWithCoins?.payload[definitions[i].name] || 0;
      if (
        state.patientPoints >= definitions[i].pointUpperLimit &&
        (!definitions[i + 1] || state.patientPoints < definitions[i + 1].pointUpperLimit)
      ) {
        definitions[i].status = 'current';
      } else if (state.patientPoints >= definitions[i].pointUpperLimit) {
        definitions[i].status = 'earned';
      } else {
        definitions[i].status = 'notEarned';
      }
    }
    state.patientCoinDefinitions = definitions;
    this.setState(state);
  }

  public setIsModalDisplayed(isDisplayed: boolean) {
    const state = this.getState();
    state.isModalDisplayed = isDisplayed;
    this.setState(state);
  }

  public getCurrentCoinInfo() {
    const state = this.getState();
    const points = state.patientPoints;
    const acquiredCoins = state.patientCoinDefinitions
      ? state.patientCoinDefinitions.filter(coin => coin.pointUpperLimit <= points)
      : [];
    const currentCoin = _maxBy(acquiredCoins, coin => coin.pointUpperLimit);
    let pointsRemaining = 0;
    if (currentCoin || state.patientCoinDefinitions?.length > 0) {
      pointsRemaining =
        currentCoin?.pointUpperLimit ?? state.patientCoinDefinitions[0].pointUpperLimit - points;
    }

    return {
      patientPoints: points,
      coinName: currentCoin ? currentCoin.label : '',
      imagePath: currentCoin ? `assets/img/${currentCoin.activeImagePath}` : '',
      imagePathTails: currentCoin ? `assets/img/${currentCoin.imagePathTails}` : '',
      pointsRemaining: pointsRemaining
    };
  }

  public async addPointsToUser(points?: number, skipSave?: boolean) {
    points = points || 1;
    const state = this.getState();
    const startingPoints = state.patientPoints;
    state.patientPoints += points;
    this.setState(state);
    let user: any = this.userStateService.model.get().User;
    if(!this.applicationContext.User.applicationData) {
      this.applicationContext.User.applicationData = {
        pointsAccumulated: 0
      }
    }
    this.applicationContext.User.applicationData.pointsAccumulated += points;
    await this.checkForNewCoin(startingPoints, state.patientPoints, user);

    if (!user.applicationData) {
      user.applicationData = {};
    }
    user.applicationData.pointsAccumulated = state.patientPoints;
    if (!skipSave) {
      user = await this.userStateService.save(user).toPromise();
    }
    return user;
  }

  private async checkForNewCoin(originalPoints: number, points: number, user: NoctemUser) {
    const coinAchieved = coinDefinitions.find(
      coin => coin.pointUpperLimit <= points && coin.pointUpperLimit > originalPoints
    );
    if (coinAchieved) {
      let patientsWithCoins = await this.extraMetaService.getOneAsync({
        'Payload.name': 'coinAchievements'
      });
      patientsWithCoins.payload[coinAchieved.name] += 1;
      patientsWithCoins = await this.extraMetaService.saveAsync(patientsWithCoins);
      this.onCoinAchieved$.next(coinAchieved);
      this.initialize(user);
    }
  }

  public async checkForLogPoints(
    completedLogs: Array<DayLog>,
    logType: string,
    questions: Array<AssessmentQuestion>
  ) {
    let points = 0;
    if (completedLogs.length === 4) {
      points++;
    } else if (completedLogs.length === 5 || completedLogs.length === 7) {
      points += 2;
    }
    if (logType === AssessmentTemplate.eveningLog.name) {
      for (const question of questions) {
        if (
          question.uniqueAnswerId &&
          question.uniqueAnswerId.startsWith('TACTIC_DID_PRACTICE_') &&
          (question.answer === true || question.answer === 'true')
        ) {
          const practiceCount = this.getPracticeCount(completedLogs, question.uniqueAnswerId);
          if (practiceCount === 3) {
            points++;
          } else if (practiceCount === 4 || practiceCount === 6) {
            points += 2;
          }
        }
      }
    }

    if (points > 0) {
      const user = await this.addPointsToUser(points);
      return user;
    } else {
      return;
    }
  }

  private getPracticeCount(logs: Array<DayLog>, tacticQuestion: string): number {
    let practiceCount = 0;
    for (const log of logs) {
      for (const question of log.eveningLog.questions) {
        if (question.uniqueAnswerId === tacticQuestion) {
          practiceCount++;
        }
      }
    }
    return practiceCount;
  }
}
