import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { infoSheet } from '../questions/info-sheet/info-sheet';


export const infoSheetDef: AssetDefinition = {
  id: 104,
  name: 'info-sheet',
  label: 'Info Sheet',
  categories: [],
  showInLibrary: false,
  readOnly: false,
  assessments: [
    infoSheet
  ],
  url: 'info-sheet'
};