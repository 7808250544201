import { Component, OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  styles: [
    `
      .c-checkbox input[type=checkbox]:checked + span { background-color: black; border-color: black; }
    `
  ],
  templateUrl: './checkbox.component.html' ,
})
export class CheckboxComponent implements OnInit {
  @Input() label: string;
  @Input() color = '#106CC8';
  @Input() inputValue: boolean;
  @Input() disabled: boolean;
  @Output() inputValueChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
  }

  onChange(event) {
    if (this.disabled) {
      event.currentTarget.checked = !event.currentTarget.checked;
      return;
    }
    this.inputValue = event.currentTarget.checked;
    this.inputValueChange.emit(this.inputValue);
  }

}

import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl( condition : boolean ) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor( private ngControl : NgControl ) {
  }

}