import { gad7Fields } from './gad7-fields';
import { pcl5Fields } from './pcl5-check-in-fields';
import { pcl5ShortFields } from './pcl5-short-fields';
import { sleepCheckInFields } from './sleep-check-in-fields';
import { teamCommunicationFields } from './team-dynamics';
import { vacdsFields } from './vacds-fields';
import { vactcsFields } from './vactcs-fields';


export const AssessmentDefinitions = [
  ...gad7Fields,
  ...pcl5ShortFields,
  ...pcl5Fields,
  ...sleepCheckInFields,
  ...teamCommunicationFields,
  ...vacdsFields,
  ...vactcsFields
];
