import { Component, OnInit, Input } from '@angular/core';
import { TreatmentPlanStateService, TreatmentPhase } from '@noctem/web';

@Component({
  selector: 'sleep-facts',
  templateUrl: './sleep-facts.component.html',
  styleUrls: []
})
export class SleepFactsComponent implements OnInit {
  @Input() phase: TreatmentPhase;
  randomSleepFact: string;

  constructor(private treatmentPlanStateService: TreatmentPlanStateService) { }

  ngOnInit() {
    this.randomSleepFact = this.treatmentPlanStateService.getRandomSleepFact(this.phase);
  }


}
