import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { flatMap as _flatMap, filter as _filter, sortBy as _sortBy } from 'lodash';


import { find as _find } from 'lodash';
import { ApiService } from '../services';
import { SearchService, AssessmentService, UserFuncService, SentModuleService } from '../services/services';
import { ModelFactory, ApplicationContext } from '../../core';
import { TranslationService } from '../translations';
import { DashboardStateService, DashboardState } from './dashboard-state.service';
import { NetworkService } from '../services/network-service';
import { LogCacheServiceInterface } from './log-cache-service.interface';
import { CalculationService } from '../services/calculation.service';
import { CALC_CONFIG } from './user';
import { BaseCalculationDefinition } from '../../core/contracts/models.calculations';

@Injectable()
export class DashboardStateCacheDecorator extends DashboardStateService {

    constructor(
        stateFactory: ModelFactory<DashboardState>,
        apiService: ApiService,
        searchService: SearchService,
        translationService: TranslationService,
        http: HttpClient,
        assessmentService: AssessmentService,
        applicationContext: ApplicationContext,
        private networkSvc: NetworkService,
        userService: UserFuncService,
        calculationService: CalculationService,
        sendModuleService: SentModuleService,
        logCacheService: LogCacheServiceInterface,
        @Inject(CALC_CONFIG) calcConstants: BaseCalculationDefinition
    ) {
    super(stateFactory, apiService, searchService,
        translationService, http, assessmentService,
        applicationContext, userService, networkSvc, calculationService, sendModuleService, logCacheService, calcConstants);
    }

    public getCheckInStatus() {
        if (this.networkSvc.hasInitialized() && !this.networkSvc.isOnline()) {
            return !this.logCacheService.hasCompletedLogTypeToday('sleep-check-in');
        } else {
            return this.stateModel.get().needsToDoDailyCheckIn;
        }
    }

    public getCommCheckInStatus() {
        if (this.networkSvc.hasInitialized() && !this.networkSvc.isOnline()) {
            return !this.logCacheService.hasCompletedLogTypeToday('team-check-in');
        } else {
            return this.stateModel.get().needsToDoTeamCheckIn;
        }
    }

    public getStressCheckInStatus() {
        if (this.networkSvc.hasInitialized() && !this.networkSvc.isOnline()) {
            return !this.logCacheService.hasCompletedLogTypeToday('stress-check-in');
        } else {
            return this.stateModel.get().needsToDoStressCheckIn;
        }
    }

    public getTrainingFeedback() {
        if (this.networkSvc.hasInitialized() && !this.networkSvc.isOnline()) {
            return !this.logCacheService.hasCompletedLogTypeToday('baseline');
        } else {
            return this.stateModel.get().needsToDoTrainingFeedback;
        }
    }

    public getPreFieldStatus() {
        if (this.networkSvc.hasInitialized() && !this.networkSvc.isOnline()) {
            return !this.logCacheService.hasCompletedLogTypeToday('pre-field-soldier');
        } else {
            return this.stateModel.get().needsToDoTrainingFeedback;
        }
    }
}
