export class Dictionary {
  // Sleep Diary Values
  public static SleepQuality: 'sleep-quality';

  // calculated values

  // flags

  // modules
  public static DreamRetraining: 'dream-retraining';
}

