import { ThresholdIndices } from './threshold-indices';

export enum teamCommunicationThreshold {
  NONE = 0,
  GREEN = 1,
  YELLOW = 2,
  RED = 3
}

export const teamCommunicationThresholdMap = [
  {
    name: 'team-communication-overall', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 3 },
      { threshold: ThresholdIndices.RED,  minValue: 4 }
    ]
  },
  {
  name: 'team-work', thresholds: [
    { threshold: ThresholdIndices.GREEN, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 3 },
    { threshold: ThresholdIndices.RED,  minValue: 4 }
  ]
  },
{
  name: 'team-communication', thresholds: [
    { threshold: ThresholdIndices.GREEN, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 3 },
    { threshold: ThresholdIndices.RED,  minValue: 4 }
  ]
},
{
  name: 'team-expressing', thresholds: [
    { threshold: ThresholdIndices.GREEN, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 3 },
    { threshold: ThresholdIndices.RED,  minValue: 4 }
  ]
},
{
  name: 'team-angry', thresholds: [
    { threshold: ThresholdIndices.RED, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 2 },
    { threshold: ThresholdIndices.GREEN,  minValue: 3 }
  ]
},
{
  name: 'team-handle-conflict', thresholds: [
    { threshold: ThresholdIndices.GREEN, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 3 },
    { threshold: ThresholdIndices.RED,  minValue: 4 }
  ]
},
{
  name: 'team-left-out', thresholds: [
    { threshold: ThresholdIndices.RED, minValue: 0 },
    { threshold: ThresholdIndices.YELLOW, minValue: 2 },
    { threshold: ThresholdIndices.GREEN,  minValue: 3 }
  ]
}
];
