import { AssessmentDefinition } from "../../../../../noctem-lib/src/core/contracts/models.calculations";

export const postFieldSurveyMedic: AssessmentDefinition = {
  name: 'post-field-survey-medic',
  description: 'Post field survey medic',
  instructions: null,
  questions: [
    {
        id: 2,
        questionText: 'Thinking about your use of the AIRE tool for managing your own issues, how helpful did you find…',
        questionEmphasis: 'The AIRE tool',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_MEDIC_AIRE_TOOL',
        autofill: false,
        answerOptions: [
            { display: 'Not at all', value: 0 },
            { display: 'A little', value: 1 },
            { display: 'Somewhat', value: 2 },
            { display: 'Very much', value: 3 },
            { display: 'NA', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 83,
        questionText: 'Did you experience any barriers to using the AIRE tool? Select all that apply',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST_TRAIN_MEDIC_BARRIERS',
        autofill: false,
        answerOptions: [
            { display: 'Soldiers did not complete assessments', value: 0 },
            { display: 'Issues with charging or power', value: 1 },
            { display: 'Issues with device connectivity to Soldierss in my unit', value: 2 },
            { display: 'I didn’t understand how the tool works', value: 3 },
            { display: 'Leaders discouraged me from using', value: 4 },
            { display: 'Soldiers concerned about confidentiality', value: 5 },
            { display: 'Data validity', value: 6 },
            { display: 'Other, please specify', value: 7},
            { display: 'Prefer not to answer', value: 'N/A', clearOtherAnswers: true },
        ],
        conditionalQuestions: [
          {
            id:1,
            questionText: 'Other',
            questionType: 'text',
            autofill: false,
            uniqueAnswerId: 'POST_TRAIN_MEDIC_BARRIERS_OTHER',
            answerOptions: [],
            displayIfAnswerEquals:[7],
          }
        ],
        isCheckInQuestion: false
    },
  ]
};
