import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sleep-recommendation-modal',
  templateUrl: './sleep-recommendation-modal.component.html',
  styles: [`
  ::ng-deep .cdk-overlay-pane .mat-dialog-container{
    width: 100vw !important;
  }
  `]
})
export class SleepRecommendationModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public sleepPrescription: { riseTime: string, bedTime: string },
    private dialogRef: MatDialogRef<SleepRecommendationModalComponent>
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
