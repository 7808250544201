import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { trainingFeedbackSurvey } from '../questions/training-survey/training-feedback-survey';
import { trainingFeedbackSurveyMedic } from '../questions/training-survey/training-feedback-survey-medic';

export const trainingFeedbackMedic: AssetDefinition = {
  id: 101, //was 10, conflict with sleep checkin
  name: 'training-feedback-medic',
  label: 'Training feedback medic',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [
    trainingFeedbackSurvey, trainingFeedbackSurveyMedic
  ],
  url: 'training-feedback-medic'
}; 