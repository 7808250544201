import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const eula: AssessmentDefinition = {
  name: 'eula',
  description: 'Eula',
  instructions: '',
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">End-User License Agreement For AIRE™ (EULA)</h2>
        <p>This End-User License Agreement ("EULA") is a legal agreement between you and Rehat, LLC d/b/a NOCTEM™ (“NOCTEM”) that grants you permission to use the AIRE™ system developed and owned by NOCTEM.</p>
      
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">LICENSE GRANT</h2>
        <p>Upon your acceptance of the terms of this EULA (by clicking “I Agree”), [and provided that you have lawfully obtained a User ID and Password], NOCTEM grants you a nonexclusive, limited term, right and license to access and use the AIRE™ System.</p>
      
        <h2 style="margin-top: 1.5rem;">INTELLECTUAL PROPERTY</h2>
        <p>As between you and NOCTEM, all intellectual rights covering or embodied in the AIRE™ System (including, without limitation, copyrights, trademarks, trade secrets) are the sole property of NOCTEM.</p>
        <p>This EULA does not grant you any title or ownership interest in the AIRE System but grants only a limited right to access and use it.</p>

      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">USER ID/PASSWORD/SECURITY</h2>
        <ul style="padding-inline-start: 20px;">
        <li><p>You will not use or attempt to use any User ID and Password for any unauthorized purpose.</p></li>
        <li><p>You may not use a third party's User ID or Password or disclose your User ID and Password to third parties.</p></li>
        <li><p>You are responsible for any and all actions taken performed with your User ID and Password.</p></li>
        <li><p>You will notify NOCTEM immediately if you become aware of any unauthorized use of your User ID or Password or any other security feature of the AIRE System.</p></li>
        </ul>
      
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">END USER OBLIGATIONS AND RESTRICTIONS</h2>
        <p>You may use the AIRE System to access the services, functions, or features it makes available to you. You agree to use the AIRE System exclusively for authorized and legal purposes, consistent with all applicable laws, regulations, and the rights of others, including privacy laws.</p>
        <p>You will not attempt to reconfigure or engineer changes to the AIRE System or attempt to gain access to any unauthorized features of the AIRE System. In addition, you will not attempt to interfere with or disrupt the AIRE System or attempt to gain access to any systems or attempt to scan or probe, hack, defeat, or otherwise compromise any security measures of the AIRE System or any systems or network portals operated by NOCTEM in connection with the AIRE System.</p>

      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">TECHNICAL DATA</h2>
        <p>You agree that NOCTEM may collect and use technical data and related information -- including but not limited to technical information about your device and system usage -- that is gathered periodically to facilitate the provision of AIRE System, updates, and product support. NOCTEM may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</p>

        <h2 style="margin-top: 1.5rem;">PERSONAL INFORMATION/PRIVACY</h2>
        <p>NOCTEM will maintain the confidentiality of any personal or health information that you provide to NOCTEM through use of the AIRE System. in accordance with applicable law and the terms of NOCTEM’s Privacy Policy. NOCTEM’s Privacy Policy, which may be revised or updated from time to time, is available for review at <a href="https://noctemhealth.com">https://noctemhealth.com</a></p>
      
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 5,
      questionText: '',
      questionEmphasis: ``,
      subText: `
        <h2 style="margin-top: 1.5rem;">DISCLAIMER OF WARRANTIES</h2>
        <p>NOCTEM PROVIDES ACCESS TO THE AIRE SYSTEM SUBJECT TO THIS EULA AND ON AN “AS IS” BASIS ONLY.</p>
        <p>NOCTEM MAKES NO WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, AS TO ANY MATTER INCLUDING, BUT NOT LIMITED TO, WARRANTY OF FITNESS FOR PARTICULAR PURPOSE, OR MERCHANTABILITY.</p>
        <p>NOCTEM LLC DOES NOT GUARANTEE THE CONTINUOUS, UNINTERRUPTED OPERATION OF THE AIRE SYSTEM OR ANY RELATED SERVICES. NOCTEM SHALL NOT BE LIABLE FOR INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES SUCH AS LOSS OF PROFITS OR INABILITY TO USE THE AIRE SYSTEM OR ANY RELATED SERVICES OR DERIVATIONS THEREOF.</p>
        <p>UNDER NO CIRCUMSTANCE WILL NOCTEM BE LIABLE FOR DAMAGES GREATER THAN AMOUNTS RECEIVED BY NOCTEM IN CONSIDERATION OF THIS SPECIFIC EULA OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS, INTERRUPTION OF BUSINESS OR RELATED EXPENSES THAT MAY ARISE FROM YOUR USE OF THE AIRE SYSTEM, INCLUDING BUT NOT LIMITED TO ANY THAT RESULT FROM DEFECTS IN THE AIRE SYSTEM, LOSS OF DATA, OR INACCURACIES OF ANY KIND.</p>

      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 6,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">PROFESSIONAL RESPONSIBILITY DISCLAIMER, INDEMNIFICATION</h2>
        <p>THE AIRE SYSTEM IS INTENDED AS A SUPPLEMENT TO, AND NOT A SUBSTITUTE FOR, THE KNOWLEDGE, EXPERTISE, SKILL, AND JUDGMENT OF PHYSICIANS, PSYCHOLOGISTS, SLEEP THERAPISTS, NURSES, OR OTHER HEALTHCARE PROFESSIONALS PROVIDING PATIENT CARE, CLINICAL DIAGNOSIS OR MEDICAL TREATMENT.</p>
        <p>YOUR USE OF THE AIRE SYSTEM IS NOT A SUBSTITUTE FOR PROFESSIONAL JUDGMENT.</p>
        <p>THE AIRE SYSTEM MAY NOT COLLECT OR CONSIDER ALL DATA, INFORMATION, RESOURCES, OR MEDICAL HISTORY NECESSARY FOR A COMPLETE OR ACCURATE HEALTH ASSESSMENT OR ADVICE, AND INFORMATION COLLECTED THROUGH THE AIRE SYSTEM MAY NOT ALWAYS BE ACCURATE.</p>
        <p>ACCORDINGLY, NOCTEM DOES NOT ASSUME AND EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR YOUR ACTS OR OMISSIONS OF WHICH MAY RESULT IN ANY LIABILITY OR DAMAGES DUE TO MALPRACTICE, FAILURE TO WARN, NEGLIGENCE OR ANY OTHER BASIS AND YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS ANY NOCTEM INDEMNIFIED PARTY AGAINST ANY CLAIMS ARISING OUT OF, OR RELATED TO, ANY SUCH MALPRACTICE, FAILURE TO WARN, NEGLIGENCE OR ANY SIMILAR CLAIM WHETHER ARISING OUT OF CONTRACT, TORT, REGULATORY OR STATUTORY CAUSES OF ACTION.</p>

      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 7,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">INDEMNIFICATION</h2>
        <p>You agree to defend, indemnify and hold harmless NOCTEM, its directors, officers, employees, attorneys and agents from all claims or demands made against them (and any related losses, expenses or attorneys’ fees) arising out of or relating to your use of or conduct regarding the AIRE System.</p>

        <h2 style="margin-top: 1.5rem;">TERMINATION</h2>
        <p>Either you or NOCTEM may terminate this EULA at any time, for any reason, or for no reason, without notice.</p>
        <p>In addition, this EULA shall terminate immediately in the event you breach this Agreement. Upon termination, you will immediately discontinue access to and use of AIRE.</p>
      
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 8,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <h2 style="margin-top: 1.5rem;">MISCELLANEOUS PROVISIONS</h2>
        <p><strong>No Waiver:</strong> No delay or omission by NOCTEM to exercise any right or power under any term of this EULA shall be a waiver of NOCTEM’s rights to enforce this EULA in any respect.</p>
        <p><strong>Severability:</strong> If any provision of this Agreement is found to be illegal, invalid, unenforceable or void by a court of competent jurisdiction, then both parties shall comply with the provisions to the extent permitted by law.  All provisions of this EULA shall be enforced to the fullest extent permitted by law.</p>
        <p><strong>No Third Party Beneficiaries:</strong> Nothing in this agreement creates any third party beneficiary rights.</p>
        <p><strong>Headings:</strong> Section headings contained in this Agreement are for reference purposes only and are not a part of this Agreement.</p>
        <p><strong>Entire Agreement:</strong> This EULA is the entire agreement between you and NOCTEM and supersedes all other agreements, understandings, statements and representations, written or oral, concerning the subject matter hereof.</p>
        <p><strong>Governing Law; Forum:</strong> This Agreement and all matters arising out of or relating to this Agreement shall be governed by the laws of the Commonwealth of Pennsylvania, without regard to its conflict of law provisions.</p>
        <ul style="padding-inline-start: 20px;">
        <li><p>Any legal action or proceeding relating to this Agreement shall be brought exclusively in the state or federal courts located in Allegheny County, Pennsylvania.</p></li>
        <li><p>You agree to submit to the exclusive jurisdiction of those courts in any such legal action or proceeding.<p></li>
        <li><p>In addition, you hereby waive any claim that any such action has been brought in an inconvenient forum, or that the court in Allegheny Count does not have jurisdiction over the Parties, or that venue is improper.</p></li>
        </ul>

      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 9,
      questionText: '',
      questionEmphasis: '',
      subText: `
        <p><strong>BY CLICKING THE BOX LABELED “I AGREE” BELOW, YOU (“YOU” OR THE “END USER”), (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) AGREE TO THE TERMS OF THIS AGREEMENT WITH THE INTENT OF BEING LEGALLY BOUND. IF YOU NOT AGREE TO THE TERMS OF THIS EULA, YOU WILL NOT HAVE PERMISSION TO USE OR RETAIN A COPY OF THE  AIRE™ System, WHICH SHOULD, THEREFORE, BE DELETED.</strong></p>
        <p>You may address questions about this EULA to NOCTEM at the following addresses:</p>
        <p>218 Oakland Avenue<br>
        Pittsburgh PA 15213<br>
        Email: <a href="support@noctemhealth.com">support@noctemhealth.com</a></p>

      `,
      questionType: 'radio',
      uniqueAnswerId: 'SOLDIER_EULA',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
