import { SleepModuleDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { aceDaytimeSleepAlgorithm } from './ace-daytime-sleep';
import { buildConfidenceAlgorithm } from './build-confidence';
import { fatigueScanningAlgorithm } from './fatigue-scanning';
import { groundingAlgorithm } from './grounding';
import { imageryRehearsalAlgorithm } from './imagery-rehearsal';
import { jetLagAlgorithm } from './jet-lag';
import { prepareThroughVisualizationAlgorithm } from './prepare-through-visualization';
import { prioritizeThoughtsAlgorithm } from './prioritize-thoughts';
import { relaxationAlgorithm } from './relaxation';
import { shiftWorkAlgorithm } from './shift-work';
import { sleepBankingAlgorithm } from './sleep-banking';
import { sleepRetrainingAlgorithm } from './sleep-retraining';
import { strategicCaffeineAlgorithm } from './strategic-caffeine';
import { strategicNappingAlgorithm } from './strategic-napping';
import { tacticalBreathingAlgorithm } from './tactical-breathing';
import { teamCommunicationAlgorithm } from './team-communication';
import { thoughtControlAlgorithm } from './thought-control';
import { upYourSleepAreaAlgorithm } from './up-your-sleep-area';

export const AlgorithmDefinitions: Array<SleepModuleDefinition> = [
  buildConfidenceAlgorithm,
  groundingAlgorithm,
  prepareThroughVisualizationAlgorithm,
  prioritizeThoughtsAlgorithm,
  tacticalBreathingAlgorithm,
  teamCommunicationAlgorithm,
  fatigueScanningAlgorithm,
  relaxationAlgorithm,
  upYourSleepAreaAlgorithm,
  strategicCaffeineAlgorithm,
  sleepRetrainingAlgorithm,
  jetLagAlgorithm,
  aceDaytimeSleepAlgorithm,
  strategicNappingAlgorithm,
  sleepBankingAlgorithm,
  imageryRehearsalAlgorithm
];
