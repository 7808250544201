import { AssessmentDefinition } from "../../../../../noctem-lib/src/core/contracts/models.calculations";

export const trainingFeedbackSurveyMedic: AssessmentDefinition = {
  name: 'training-feedback-survey-medic',
  description: 'Training feedback survey medic',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Please select the core symptoms of an acute stress reaction (click all that apply):',
      questionEmphasis: null,
      questionType: 'checkbox',
      uniqueAnswerId: 'POST-TOOL-MEDIC-ACUTE-STRESS',
      autofill: false,
      answerOptions: [
        { display: 'Lethargy', value: 0 },
        { display: 'Hypervigilance', value: 1 },
        { display: 'Re-experiencing of the traumatic event through thoughts, dreams, images or flashbacks', value: 2 },
        { display: 'Loss of appetite', value: 3 },
        { display: 'Numbing or absence of normal emotional responses', value: 4 },
        //{ display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: []
    },
    {
        id: 1,
        questionText: 'Please select the core symptoms of problematic anxiety (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-MEDIC-PROBLEMATIC-ENVIRONMENT',
        autofill: false,
        answerOptions: [
          { display: 'Activation of the sympathetic nervous system (fast pulse, sweaty palms, shaky hands, dry mouth)', value: 0 },
          { display: 'Restlessness or feeling on edge', value: 1 },
          { display: 'Loss of interest or pleasure in activities', value: 2 },
          { display: 'Excessive worrying', value: 3 },
          { display: 'Disorientation', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
      },
    {
        id: 2,
        questionText: 'Please select the core symptoms of sleep disturbance (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-MEDIC-SLEEP-DISTURBANCE',
        autofill: false,
        answerOptions: [
          { display: 'Auditory hallucinations', value: 0 },
          { display: 'Nightmares and/or bad dreams', value: 1 },
          { display: 'Daytime sleepiness and/or fatigue', value: 2 },
          { display: 'Difficulty falling asleep or staying asleep', value: 3 },
          { display: 'Non-restorative sleep', value: 4 },
          { display: 'Restless sleep', value: 5 },
          { display: 'Muscle tension', value: 6 },
          { display: 'GI problems', value: 7 },
          { display: 'Irritability', value: 8 },
          { display: 'Concentration problems', value: 9 },
          //{ display: 'Prefer not to answer', value: 10 },
        ],
        conditionalQuestions: []
    },
    {
        id: 3,
        questionText: 'Please select the core signs of dysfunctional team communication (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-MEDIC-DYSFUNCTIONAL-TEAM',
        autofill: false,
        answerOptions: [
          { display: 'Distrust between team members', value: 0 },
          { display: 'Reluctance to offer ideas or discuss feelings openly', value: 1 },
          { display: 'Excessive talking', value: 2 },
          { display: 'The same team members always taking the lead', value: 3 },
          { display: 'Repetitive disagreements', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 4,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Discuss stress and anxiety issues with your Soldiers?',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-SOLDIER-STRESS',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 5,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Discuss team communication issues that arise between your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-COMM-ISSUES',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 6,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Discuss sleep problems with your Soldiers?',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-DISCUSS-SLEEP',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 7,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Assess stress and anxiety issues with your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-ASSESS-STRESS',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 8,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Provide interventions for stress and anxiety issues with your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-PROVIDE-INTERVENTION',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 9,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Assess for team communication issues between your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-ASSESS-TEAM-COMM',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 10,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Provide interventions for team communication issues with your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-PROVIDE-TEAM-COMM',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 11,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Assess sleep problems with your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-ASSESS-SLEEP-PROBLEMS',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 12,
        questionText: 'How confident are you in your ability to…',
        questionEmphasis: 'Provide interventions for sleep problems and/or mitigate fatigue with your Soldiers',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-MITIGATE-FATIGUE',
        autofill: false,
        answerOptions: [
          { display: 'Not at all confident', value: 0 },
          { display: 'Slightly confident', value: 1 },
          { display: 'Somewhat confident', value: 2 },
          { display: 'Moderately confident', value: 3 },
          { display: 'Extremely confident', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 13,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements: I learned specific actions in this training session to…',
        questionEmphasis: 'Manage stress and anxiety in my soldiers.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-MANAGE-STRESS',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 14,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements: I learned specific actions in this training session to…',
        questionEmphasis: 'Manage team communication issues that arise between my Soldiers.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-MANAGE-TEAM-COMM',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 118,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements: I learned specific actions in this training session to…',
        questionEmphasis: 'Manage sleep and fatigue problems in my Soldiers.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-MANAGE-SOLDIER-FATIGUE',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
    {
        id: 16,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements: I learned specific actions in this training session to…',
        questionEmphasis: 'Maximize the performance of the Soldiers in my unit in a deployed environment.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MEDIC-MAX-PERFORMANCE',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: []
    },
  ]
};
