import { ThresholdIndices } from './threshold-indices';

export const isiThresholdMap = [
  {
    name: 'isi-overall', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 8 },
      { threshold: ThresholdIndices.RED,  minValue: 14 }
    ]
  }
];
