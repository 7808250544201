export enum xAxisLabelTypes {
  None,
  Dates,
  AnswerOptions,
  Static
}

export enum ChartTypes {
  Bar = 'bar',
  Radar = 'radar',
  Pie = 'pie',
  Line = 'line',
  Barriers = 'barriers'
}

export enum SoliderAssessmentDisplay {
  SleepLatency = 'Sleep Latency',
  StressConcerns = 'Stress Concerns',
  OverallStress = 'Overall Stress',
  StressReactions = 'Stress Reactions',
  TeamworkConcerns = 'Teamwork Concerns',
  OverallTeamwork = 'Overall Teamwork'
}

export enum StatField {
  SleepQuality = 'sleep-quality',
  SleepEfficiency = 'sleep-efficiency',
  TotalSleep = 'total-sleep-past-24',
}

export const unitStatsDict = {
  fields: [{name:'total-sleep-past-24', average:false}, {name:'vacds', average:false},{name:'vactcs', average:false}]
}

export const soldierChartTabsDict = [
  {
    name: 'Sleep',
    selectedSubTab: 'latency',
    subTabs: [
      {
        display: 'Sleep Latency',
        tabId: 'latency',
        assessmentType: 'sleep-check-in',
        fields: ['sleep-latency'],
        chartType: ChartTypes.Line,
        xAxisLabelType: xAxisLabelTypes.Dates,
        statistics: [
          {label: 'Quality', field: 'sleep-quality', value: '-' },
          {label: 'Efficiency', field: 'sleep-efficiency', value: '-' },
          {label: 'Duration', field: 'total-sleep-past-24', value: '-' }]
      }]
  },
  {
    name: 'Stress',
    selectedSubTab: 'gad7-overall',
    subTabs: [
              {
                display: 'Stress Concerns',
                tabId: 'gad7-overall',
                assessmentType: 'gad7',
                fields: ['gad7-overall'],
                chartType: ChartTypes.Line,
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Overall Stress',
                tabId: 'vacds',
                assessmentType: 'vacds',
                fields: ['vacds'],
                chartType: ChartTypes.Line,
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Stress Reactions',
                tabId: 'pcl5Short-overall',
                assessmentType: 'pcl5Short',
                fields: ['pcl5Short-overall'],
                chartType: ChartTypes.Line,
                xAxisLabelType: xAxisLabelTypes.Dates
              },
            ]
  },
  {
    name: 'Team',
    selectedSubTab: 'teamwork-concerns',
    subTabs: [
              {
                display: 'Teamwork Concerns',
                tabId: 'teamwork-concerns',
                assessmentType: 'vacds',

                fields: ['team-left-out',
                          'team-work',
                          'team-communication',
                          'team-expressing',
                          'team-angry',
                          'team-handle-conflict'],
                chartType: ChartTypes.Radar,
                xAxisLabelType: xAxisLabelTypes.Static,
                xAxisLabels: [
                  { label: ['Isolation',''], value: 0 },
                  { label: ['Teamwork',''], value: 1 },
                  { label: ['','Team', 'Communication'], value: 2 },
                  { label: ['', 'Self Expression'], value: 3 },
                  { label: ['','Irritability'], value: 4 },
                  { label: ['Conflict', 'Management', ''], value: 5}]

              },
              {
                display: 'Overall Teamwork',
                tabId: 'overall-teammwork',
                assessmentType: 'vactcs',
                fields: ['vactcs'],
                chartType: ChartTypes.Line,
                xAxisLabelType: xAxisLabelTypes.Dates
              }
            ]
  }
];


export const medicChartTabsDict = [
  {
    name: 'Teamwork',
    selectedSubTab: 'team-communication',
    subTabs: [
              {
                display: 'Overall',
                tabId: 'team-communication',
                fields: [{name: 'vactcs', average: false}],
                yAxisLabel: 'Service Members',
                chartType: ChartTypes.Bar,
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Concerns',
                tabId: 'concerns',
                fields: [{name: 'team-left-out', average: true},
                         {name: 'team-work', average: true},
                         {name: 'team-communication', average: true},
                         {name: 'team-expressing', average: true},
                         {name: 'team-angry', average: true},
                         {name: 'team-handle-conflict', average: true}],
                chartType: ChartTypes.Radar,
                xAxisLabelType: xAxisLabelTypes.Static,
                xAxisLabels: [
                    { label: 'Isolation', value: 0 },
                    { label: 'Teamwork', value: 1 },
                    { label: ['Team', 'Communication'], value: 2 },
                    { label: 'Self Expression', value: 3 },
                    { label: 'Irritability', value: 4 },
                    { label: ['Conflict', 'Management'], value: 5}]
              }
            ]
  },
  {
    name: 'Sleep',
    selectedSubTab: 'quality',
    subTabs: [
              {
                display: 'Quality',
                tabId: 'quality',
                assessmentType: 'sleep-check-in',
                fields: [{name: 'sleep-quality', average: false }],
                chartType: ChartTypes.Bar,
                yAxisLabel: 'Service Members',
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Latency',
                tabId: 'latency',
                assessmentType: 'sleep-check-in',
                fields: [{name: 'sleep-latency', average: false}],
                chartType: ChartTypes.Bar,
                yAxisLabel: 'Service Members',
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Duration',
                tabId: 'duration',
                assessmentType: 'sleep-check-in',
                fields: [{name: 'total-sleep-past-24', average: false}],
                chartType: ChartTypes.Bar,
                yAxisLabel: 'Service Members',
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Efficiency',
                tabId: 'efficiency',
                assessmentId: 'sleep-check-in',
                fields: [{name: 'sleep-efficiency', average: false}],
                chartType: ChartTypes.Bar,
                yAxisLabel: 'Service Members',
                xAxisLabelType:  xAxisLabelTypes.Dates
              },
              {
                display: 'Barriers',
                tabId: 'barriers',
                assessmentType: 'sleep-check-in',
                fields: [{name: 'disrupted-sleep'}, {name: 'prevented-sleep', average: false}],
                chartType: ChartTypes.Barriers,
                yAxisLabel: 'Occurrences',
                xAxisLabelType: xAxisLabelTypes.Static,
                xAxisLabels: [

                ]
              }
            ]
  },
  {
    name: 'Stress',
    selectedSubTab: 'vacds',
    subTabs: [
              {
                display: 'Overall',
                tabId: 'vacds',
                assessmentType: 'vacds',
                fields: [{name: 'vacds', average: false}],
                chartType: ChartTypes.Bar,
                yAxisLabel: 'Service Members',
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Concerns',
                tabId: 'gad7',
                assessmentType: 'gad7',
                fields: [{name: 'gad7-overall', average: false}],
                chartType: ChartTypes.Bar,
                yAxisLabel: 'Service Members',
                xAxisLabelType: xAxisLabelTypes.Dates
              },
              {
                display: 'Reactions',
                tabId: 'reactions',
                assessmentType: 'pcl5Short',
                yAxisLabel: 'Service Members',
                // fields: ['pcl5-relive', 'pcl5-avoid', 'pcl5-distant', 'pcl5-irritable'],
                fields:[{name: 'pcl5Short-overall', average: false}],
                chartType: ChartTypes.Bar,
                xAxisLabelType: xAxisLabelTypes.Dates,
              }
            ]
  }
];
