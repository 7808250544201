import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { eula } from '../questions/eula/eula';


export const eulaDef: AssetDefinition = {
  id: 103,
  name: 'eula',
  label: 'Eula',
  categories: [],
  showInLibrary: false,
  readOnly: false,
  assessments: [
    eula
  ],
  url: 'eula'
};