import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const infoSheet: AssessmentDefinition = {
  name: 'info-sheet',
  description: 'Info Sheet',
  instructions: '',
  questions: [
    {
      id: 0,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p><b>Title: </b>Refining Digital Sleep and Behavioral Health Optimization System Apps</p>
                <p><b>Contact Info: </b>Anne Germain; <a href="mailto:support@noctemhealth.com">support@noctemhealth.com</a> or (412) 212-3077</p>
                <p><b>Version: </b>15 DECEMBER 2023</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p>NOCTEM is developing its AIRE prototype designed to help monitor and manage sleep, fatigue and the resulting challenges that can impact readiness and performance.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p>You have been invited to use the AIRE digital health tool as part of a process improvement project that aims to refine AIRE apps and evaluate their relevance and usability among service members.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p>As such, you will be asked to complete assessments that appear on the AIRE app,  provide feedback on the usability of the tool, and usefulness of the recommended modules.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p>Your use of the app will allow us to continue to improve the AIRE system for use in military populations. Your participation is voluntary.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 5,
      questionText: 'Key Information for you to consider (1):',
      questionEmphasis: ``,
      subText: `<ul>
      <li>Using the AIRE app, completing assessments, and providing feedback on the tool is completely voluntary. There are no penalties, and you will not lose anything if you decide not to participate.</li>
      <li>Data and feedback collected during the evaluation period is entirely anonymous. No personal or identifiable information will be collected.</li>
      </ul>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 6,
      questionText: 'Key Information for you to consider (2):',
      questionEmphasis: ``,
      subText: `<ul>
      <li><b>Purpose.</b> We are doing this process improvement project to evaluate how we can improve the feasibility, acceptability, and usability of the AIRE apps as a way of monitoring and managing sleep, fatigue and resulting behavioral health symptoms that can impact performance and readiness.</li>
      <li><b>Duration.</b> The assessment will last up to 8 weeks.</li>
      </ul>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 7,
      questionText: 'Key Information for you to consider (3):',
      questionEmphasis: ``,
      subText: `<p><b>Procedures and Activities.</b> If you choose to provide feedback on the AIRE app, you will be asked to download the AIRE app onto your smartphone and a  unique activation code will be provided to you. Through the evaluation period, you will be asked to use the AIRE app and explore its content. At the end of the evaluation period, you will also asked to answer questions about its usability of the app.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 8,
      questionText: 'Key Information for you to consider (4):',
      questionEmphasis: ``,
      subText: `<ul>
      <li><b>Risks.</b> The risk of using the AIRE app is similar to using other apps on your smartphone.</li>
      <li><b>Benefits.</b> There is no anticipated benefit for providing your input on the usability of the AIRE apps.</li>
      </ul>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 9,
      questionText: 'Key Information for you to consider (5):',
      questionEmphasis: ``,
      subText: `<p>You may skip questions on the questionnaire that you do not wish to answer.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 10,
      questionText: 'Why are we doing this assessment?',
      questionEmphasis: ``,
      subText: `<p>The goal is to evaluate the usability and feasibility of the digital health tool to determine how such digital health tools can be improved.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 11,
      questionText: 'What happens to the information collected for this assessment?',
      questionEmphasis: ``,
      subText: `<p>Your leadership may receive a summary of the aggregated results of this process improvement project. This summary may include how many check-ins have been completed, page views, time on page, and other data summarized for the entire group of people who used the AIRE app. Individual answers will not be provided.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 12,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p>It is important to note that your data is entirely anonymous. The data is not collected for research purposes: the data will only be used to improve the AIRE apps.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 13,
      questionText: null,
      questionEmphasis: ``,
      subText: `<p>If you feel that you need help with sleep, fatigue, or any behavioral health challenges, resources are available through your embedded behavioral health specialist, medical clinic, or chaplain.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 14,
      questionText: 'Who can I contact if I have questions about this process improvement process?',
      questionEmphasis: ``,
      subText: `<p>If you have questions please contact NOCTEM via email (<a href="mailto:support@noctemhealth.com">support@noctemheatlh.com</a>) or via telephone at (412) 212-3077.</p>`,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 15,
      questionText: 'I read this information sheet and I agree to allow survey to be used for the purpose of optimizing the AIRE platform.',
      questionEmphasis: ``,
      subText: ``,
      questionType: 'radio',
      uniqueAnswerId: 'INFO_SHEET',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
