import { toLower } from 'lodash';

export class ActivityLogPrintResult {
    User:string;
    StartedOn:string;
    EndedOn:string;
    ResponseTimeSeconds:string;
    Request:any;
    Response:any;
    
    constructor(User:string, StartedOn:string, EndedOn:string, ResponseTimeSeconds:string, Request:any, Response:any) {
        this.User = User;
        this.StartedOn = StartedOn;
        this.EndedOn = EndedOn;
        this.ResponseTimeSeconds = ResponseTimeSeconds;
        this.Request = Request;
        this.Response = Response;
    }
}

export class ActivityLogPrintParams {
    ModelName:string;
    FunctionName:string;
    RequestSearches:string[];
    PropertyPaths:string[];
    Size:number;
    LogFile:string;
    Query:any;
    
    constructor(ModelName:string, FunctionName:string, RequestSearches:string[], PropertyPaths:string[], size:number, logFile:string) {
        this.ModelName = ModelName || null;
        this.FunctionName = FunctionName || null;
        this.RequestSearches = RequestSearches || [];
        this.PropertyPaths = PropertyPaths || [];
        this.Size = size || 100;
        this.LogFile = logFile || null;
        this.Query = null;
    }
    
    public buildQuery() {
        let andArray:any[] = [];
        if (this.FunctionName) {andArray.push({ 'Payload.FunctionName': this.FunctionName });}
        if (this.ModelName) {andArray.push({ 'Payload.Model': toLower(this.ModelName) });}
        for (let text of this.RequestSearches) {
            andArray.push({ 'Payload.Request': { '$regex': text, $options: '-i' } });
        }
        this.Query = { '$and': andArray };
    }
}