import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SleepRecommendationModalComponent } from '../sleep-recommendation-modal/sleep-recommendation-modal.component';
import { take } from 'rxjs/operators';
import { TreatmentPlanStateService } from '@noctem/web';

@Component({
  selector: 'sleep-recommendation',
  templateUrl: './sleep-recommendation.component.html',
  styles: [
    `.previous-week{
      background-color:#808080 !important;
      border-color:#808080 !important;
    }`
  ]
})
export class SleepRecommendationComponent implements OnInit {
  @Input() riseTime;
  @Input() bedTime;
  @Input() isPast:boolean;

  constructor(private dialog: MatDialog, private treatmentPlanStateService: TreatmentPlanStateService) { }

  ngOnInit() {
  }

  openModal() {
    if (!this.isPast) {
      this.treatmentPlanStateService.setIsModalDisplayed(true);
      const dialogRef: any = this.dialog.open(SleepRecommendationModalComponent, {
        height: '100vh',
        width: '100vw',
        data: { riseTime: this.riseTime, bedTime: this.bedTime }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(r => {
        this.treatmentPlanStateService.setIsModalDisplayed(false);
      });
    }
  }

}
