import { trainingFeedback } from './training-feedback';
import { sleepCheckIn } from './sleep-check-in';
import { teamCheckIn } from './team-check-in';
import { stressCheckIn } from './stress-check-in';
import { twoInternalSystemsDef } from './two-internal-sleep-systems';
import { sleepBankingDef } from './sleep-banking';
import { aceDaytimeSleepDef } from './ace-daytime-sleep';
import { controlJetLagDef } from './control-jet-lag';
import { dangersOfFatigueDef } from './dangers-of-fatigue';
import { dreamRetrainingDef } from './dream-retraining';
import { fatigueScanningDef } from './fatigue-scanning';
import { gaugeSleepHealthDef } from './gauge-sleep-health';
import { unwindBeforeBedDef } from './unwind-before-bed';
import { strategicCaffeineDef } from './strategic-caffeine';
import { upYourSleepAreaDef } from './up-your-sleep-area';
import { buildConfidenceDef } from './build-confidence';
import { powerOfSleepDef } from './power-of-sleep';
import { prioritizeThoughtsDef } from './compartmentalization';
import { groundingDef } from './grounding';
import { strategicNappingDef } from './strategic-napping';
import { prepareThroughVisualizationDef } from './prepare-through-visualization';
import { relaxationDef } from './relaxation';
import { teamCommunicationDef } from './team-communication';
import { tacticalBreathingDef } from './tactical-breathing';
import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { quickstartDef } from './quickstart';
import { preFieldSoldier } from './pre-field-soldier';
import { preFieldMedic } from './pre-field-medic';
import { trainingFeedbackMedic } from './training-feedback-medic';
import { trainingFeedbackSoldier } from './training-feedback-soldier';
import { postFieldSoldier } from './post-field-soldier';
import { postFieldMedic } from './post-field-medic';
import { eulaDef } from './eula'
import { infoSheetDef } from './info-sheet';

export const ModuleTemplates = [
  aceDaytimeSleepDef,
  trainingFeedback,
  buildConfidenceDef,
  controlJetLagDef,
  dangersOfFatigueDef,
  dreamRetrainingDef,
  fatigueScanningDef,
  gaugeSleepHealthDef,
  groundingDef,
  quickstartDef,
  powerOfSleepDef,
  prepareThroughVisualizationDef,
  preFieldSoldier,
  preFieldMedic,
  trainingFeedbackMedic,
  trainingFeedbackSoldier,
  prioritizeThoughtsDef,
  relaxationDef,
  sleepCheckIn,
  teamCheckIn,
  sleepBankingDef,
  strategicCaffeineDef,
  strategicNappingDef,
  stressCheckIn,
  tacticalBreathingDef,
  teamCommunicationDef,
  twoInternalSystemsDef,
  unwindBeforeBedDef,
  upYourSleepAreaDef,
  postFieldMedic,
  postFieldSoldier,
  eulaDef,
  infoSheetDef
] as Array<AssetDefinition>;
