import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';

export const preFieldSurvey: AssessmentDefinition = {
  name: 'pre-field-survey',
  description: 'Baseline Survey',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText:
        'I received a briefing and I agree to allow my survey responses to be used for the purpose of optimizing the AIRE platform.',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_CONSENT',
      autofill: false,
      answerOptions: [
        { display: 'No', value: false },
        { display: 'Yes', value: true }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'Gender',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_GENDER',
      autofill: false,
      answerOptions: [
        { display: 'Male', value: 0 },
        { display: 'Female', value: 1 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: 'What is your current age?',
      questionEmphasis: null,
      questionType: 'dropdown',
      uniqueAnswerId: 'PRE_TRAIN_AGE',
      showPreferNotToAnswer:true,
      autofill: false,
      dropdownAnswerOptions: [
        { display: 'min', value: 17 },
        { display: 'max', value: 99 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false,
      answerOptions: []
    },
    {
      id: 3,
      questionText: 'Grade/Rank?',
      questionEmphasis: null,
      questionType: 'text',
      uniqueAnswerId: 'PRE_TRAIN_RANK',
      autofill: false,
      answerOptions: [
      { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      showPreferNotToAnswer: true
    },
    {
      id: 4,
      questionText: 'What is your MOS?',
      questionEmphasis: null,
      questionType: 'text',
      uniqueAnswerId: 'PRE_TRAIN_MOS',
      autofill: false,
      answerOptions: [
      { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      showPreferNotToAnswer: true
    },
    {
      id: 5,
      questionText: 'Highest level of civilian education?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_EDUCATION',
      autofill: false,
      answerOptions: [
        { display: 'Some high school', value: 0 },
        { display: 'High school diploma / GED', value: 1 },
        // eslint-disable-next-line prettier/prettier
        { display: 'Some college / Associate\'s degree', value: 2 },
        // eslint-disable-next-line prettier/prettier
        { display: 'Bachelor\'s degree', value: 3 },
        { display: 'Graduate degree', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 6,
      questionText: 'How many years have you been in the military?',
      questionEmphasis: null,
      questionType: 'dropdown',
      uniqueAnswerId: 'PRE_TRAIN_MILITARY',
      autofill: false,
      dropdownAnswerOptions: [
        { display: 'min', value: 1 },
        { display: 'max', value: 39 },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false,
      showPreferNotToAnswer: true,
      answerOptions: []
    },
    {
      id: 7,
      questionText: 'What is your current marital status?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_MARITAL',
      autofill: false,
      answerOptions: [
        { display: 'Single, never married', value: 0 },
        { display: 'Married', value: 1 },
        { display: 'Separated', value: 2 },
        { display: 'Divorced', value: 3 },
        { display: 'Widowed', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 8,
      questionText: 'What is your race/ethnicity? (Check all that apply)',
      questionEmphasis: null,
      questionType: 'checkbox',
      uniqueAnswerId: 'PRE_TRAIN_RACE',
      autofill: false,
      answerOptions: [
        { display: 'American Indian or Alaska Native', value: 0 },
        { display: 'Asian', value: 1 },
        { display: 'Black or African American', value: 2 },
        { display: 'Hispanic or Latino', value: 3 },
        { display: 'Native Hawaiian or Other Pacific Islander', value: 4 },
        { display: 'White', value: 5 },
        { display: 'Other, please specify', value: 6 },
        { display: 'Prefer not to answer', value: 'N/A', clearOtherAnswers: true }
      ],
      conditionalQuestions: [
        {
          id: 1,
          questionText: 'Other',
          questionType: 'text',
          autofill: false,
          uniqueAnswerId: 'PRE_TRAIN_RACE_OTHER',
          answerOptions: [],
          displayIfAnswerEquals: [6]
        }
      ],
      isCheckInQuestion: false
    },
    {
      id: 21,
      questionText:
        'Please select the strategies that are helpful when experiencing stress (click all that apply):',
      questionEmphasis: null,
      questionType: 'checkbox',
      uniqueAnswerId: 'PRE_TRAIN_ANXIETY',
      autofill: false,
      answerOptions: [
        { display: 'Practice deliberate slow breathing.', value: 0 },
        { display: 'Identify and replace problematic thoughts.', value: 1 },
        { display: 'Practice visualizing a successful performance of a relevant task.', value: 2 },
        {
          display:
            'Systematically tense and relax different muscle groups, paying attention to the sensations.',
          value: 3
        },
        { display: 'Focus on negative thoughts until they go away.', value: 4 },
        { display: 'Prefer not to answer', value: 6, clearOtherAnswers: true }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 22,
      questionText:
        'Please select the strategies that are helpful when experiencing a sleep disturbance (click all that apply):',
      questionEmphasis: null,
      questionType: 'checkbox',
      uniqueAnswerId: 'PRE_TRAIN_SLEEP_DISTURBANCE',
      autofill: false,
      answerOptions: [
        { display: 'Using caffeine strategically and not 6 hours before bedtime', value: 0 },
        { display: 'Napping briefly before your shift', value: 1 },
        {
          display: 'Limiting light exposure, caffeine, nicotine or exercise before sleeping',
          value: 2
        },
        {
          display:
            'Prepare for a period with reduced sleep by getting extra hours of sleep every day',
          value: 3
        },
        {
          display:
            'Prepare for a period with reduced sleep by limiting your sleep each day so your body gets used to it',
          value: 4
        },
        {
          display: 'Creating and following a sleep routine to decompress before falling asleep',
          value: 5
        },
        { display: 'Prefer not to answer', value: 'N/A', clearOtherAnswers: true }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 23,
      questionText:
        'Please select the strategies that are helpful when experiencing dysfunctional team communication (click all that apply):',
      questionEmphasis: null,
      questionType: 'checkbox',
      uniqueAnswerId: 'PRE_TRAIN_DYSFUNCTIONAL',
      autofill: false,
      answerOptions: [
        {
          display:
            'Communicate all relevant information concisely with easily understood terms and concepts.',
          value: 0
        },
        {
          display: 'Use “I Statements” to express your thoughts, feelings and concerns.',
          value: 1
        },
        { display: 'Always communicate by yelling as loud as possible.', value: 2 },
        { display: 'Describe the situation that is creating conflict in a calm manner.', value: 3 },
        {
          display: 'Actively listen when others are speaking and give appropriate feedback.',
          value: 4
        },
        { display: 'Prefer not to answer', value: 'N/A', clearOtherAnswers: true }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 49,
      questionText:
        'Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).',
      questionEmphasis: 'Difficulty falling asleep',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_DIFFICULTY_SLEEP',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Mild', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Severe', value: 3 },
        { display: 'Very severe', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 50,
      questionText:
        'Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).',
      questionEmphasis: 'Difficulty staying asleep',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_STAYING_ASLEEP',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Mild', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Severe', value: 3 },
        { display: 'Very severe', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 51,
      questionText:
        'Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).',
      questionEmphasis: 'Problem waking up too early',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_STAYING_ASLEEP',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Mild', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Severe', value: 3 },
        { display: 'Very severe', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 52,
      questionText: 'How SATISFIED/DISSATISFIED are you with your CURRENT sleep pattern?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_SLEEP_PATTERN',
      autofill: false,
      answerOptions: [
        { display: 'Very Satisfied', value: 0 },
        { display: 'Satisfied', value: 1 },
        { display: 'Moderately Satisfied', value: 2 },
        { display: 'Dissatisfied', value: 3 },
        { display: 'Very Dissatisfied', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 53,
      questionText:
        ' How NOTICEABLE to others do you think your sleep problem is in terms of impairing the quality of your life?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_NOTICEABLE',
      autofill: false,
      answerOptions: [
        { display: 'Not at all Noticeable', value: 0 },
        { display: 'A little', value: 1 },
        { display: 'Somewhat', value: 2 },
        { display: 'Much', value: 3 },
        { display: 'Very Much Noticeable', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 54,
      questionText: 'How WORRIED/DISTRESSED are you about your current sleep problem?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_DISTRESSED',
      autofill: false,
      answerOptions: [
        { display: 'Not at all Worried', value: 0 },
        { display: 'A little', value: 1 },
        { display: 'Somewhat', value: 2 },
        { display: 'Much', value: 3 },
        { display: 'Very Much Worried', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 55,
      questionText:
        'To what extent do you consider your sleep problem to INTERFERE with your daily functioning (e.g. daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.) CURRENTLY?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_INTERFERE',
      autofill: false,
      answerOptions: [
        { display: 'Not at all Interefering', value: 0 },
        { display: 'A little', value: 1 },
        { display: 'Somewhat', value: 2 },
        { display: 'Much', value: 3 },
        { display: 'Very Much Interefering', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 66,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'Sitting and reading',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_SITTING',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 67,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'Watching TV',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_TV',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 68,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'Sitting, inactive, in public place (e.g. a theater or meeting)',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_INACTIVE',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 69,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'As a passenger in a car for an hour without a break',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_PASSENGER',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 70,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'Lying down to rest in the afternoon when circumstances permit',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_LIE_DOWN',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 71,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'Sitting and talking to someone',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_TALKING',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 72,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'Sitting quietly after a lunch without alcohol',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_AFTER_LUNCH',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 73,
      questionText:
        'How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired?  This refers to your usual way of life in recent times.  Even if you haven’t done some of these things recently try to work out how they would have affected you.',
      questionEmphasis: 'In a car, while stopped for a few minutes in traffic',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_TRAFFIC',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 77,
      questionText: 'In the past 7 days…',
      questionEmphasis: 'How often did your fatigue make you feel less alert?',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_FATIGUE',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 78,
      questionText: 'In the past 7 days…',
      questionEmphasis:
        'How often did you have to push yourself to get things done because of your fatigue?',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_PUSH_FATIGUE',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 82,
      questionText:
        'In the past month, on average, how many caffeinated beverages do you consume on a daily basis? (One caffeinated beverage is equal to one 8oz cup of coffee, one 8oz energy drink or 16oz of tea)',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_CAFFEINE_BEVERAGES',
      autofill: false,
      answerOptions: [
        { display: 'None- I don’t drink caffeinated beverages', value: 0 },
        { display: 'Less than once a week', value: 1 },
        { display: 'At lease once a week, but not every day', value: 2 },
        { display: '1 per day', value: 3 },
        { display: '2 per day', value: 4 },
        { display: '3 per day', value: 5 },
        { display: '4 per day', value: 6 },
        { display: '5 or more per day', value: 7 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 131,
      questionText: 'How much difficulty do you CURRENTLY have with the following',
      questionEmphasis: 'The quality of your work',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_QUALITY_WORK',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 132,
      questionText: 'How much difficulty do you CURRENTLY have with the following',
      questionEmphasis: 'The accuracy of your work',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_ACCURACY_WORK',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 133,
      questionText: 'How much difficulty do you CURRENTLY have with the following',
      questionEmphasis: 'Your ability to complete assigned tasks',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_COMPLETE_TASKS',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 134,
      questionText: 'How much difficulty do you CURRENTLY have with the following',
      questionEmphasis: 'Your overall work performance',
      questionType: 'radio',
      uniqueAnswerId: 'PRE_TRAIN_WORK_PERFORMANCE',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
