import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const subject = new Subject<any>();

const APP_NATIVE_MESSAGES = {
    APP_ACTIVE: "APP_ACTIVE",
    APP_INACTIVE: "APP_INACTIVE",
}

@Injectable({
    providedIn: 'root',
})
class AppNativeBus {
    
    getMessages() {
        return subject.asObservable();
    }

    notify(key, data = {}) {
        subject.next({ key: key, data: data });
    }
}
export { AppNativeBus as AppNativeBus, APP_NATIVE_MESSAGES as APP_NATIVE_MESSAGES }