import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { postFieldSurvey } from '../questions/training-survey/post-field-survey';
import { postFieldSurveySoldier } from '../questions/training-survey/post-field-survey-soldier';

export const postFieldSoldier: AssetDefinition = {
  id: 104,
  name: 'post-field-soldier',
  label: 'Post field soldier',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [
    postFieldSurvey, postFieldSurveySoldier
  ],
  url: 'post-field-soldier'
}; 