import { Component, OnInit, Input } from '@angular/core';
import {
  TreatmentPlanStateService,
  TreatmentPhase,
} from '../../../../../../noctem-lib/src/lib/state/treatment-plan-state.service';
import {
  UserAction,
  UserActionType,
  SleepPrescription
} from '../../../../../../noctem-lib/src/lib/services/models';
import { LogStateService, ApplicationContext } from '@noctem/web';
import moment from 'moment';
@Component({
  selector: 'dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styles: [
    `
      .currentWeek {
        margin-top: -4px;
        color: blue;
      }

      .expired {
        color: gray !important;
      }
    `
  ]
})
export class DashboardPanelComponent implements OnInit {
  @Input() phase: TreatmentPhase;
  @Input() currentPhase: TreatmentPhase;
  @Input() sleepPrescription: SleepPrescription;
  @Input() incompleteLogCount: number;
  public selectedSleepPrescription: SleepPrescription = new SleepPrescription();
  public userActions: Array<any> = [];
  public isFuture = false;
  public isPast = false;
  public isSorted = false;
  public isTreatmentActive: boolean;
  public treatmentStartDate;

  constructor(
    private treatmentPlanStateService: TreatmentPlanStateService,
    private logStateService: LogStateService,
    private applicationContext: ApplicationContext,

  ) {}

  async ngOnInit() {
    // Use this.treatmentStartDate for display/formatting on the front end only
    this.treatmentStartDate = moment(this.applicationContext.User.treatmentStartDate);
    // Take the treatmentStartDate and convert to the users local time for comparison
    let treatmentStartDateCompare = moment(this.applicationContext.User.treatmentStartDate).subtract(moment().utcOffset(), 'minutes').startOf('day')
    // Today is in local time
    let today = moment();
    // Calculate if the user's treatment is active right now
    this.isTreatmentActive = today >= treatmentStartDateCompare;
    //console.log('this.treatmentStartDate: ', this.treatmentStartDate, treatmentStartDateCompare, today)

    // This loads the actions for all of the phases
    await this.treatmentPlanStateService.buildUserActionList(this.phase).then(userActions => {
      return this.finishBuildUserActions(userActions);
    });

    if (this.phase && this.currentPhase) {
      this.isFuture = this.phase.order > this.currentPhase.order;
      this.isPast =
        (this.phase.order < this.currentPhase.order ||
          this.treatmentPlanStateService.isCurrentPhaseEnded());
    }
  }

  finishBuildUserActions(userActions: any[]): void {
    if (userActions.length) {
      userActions.forEach(ua => {
        if (ua.userActionType === UserActionType.logs.name) {
         if (this.incompleteLogCount > 0) {
           ua.displayText = ua.displayText + ' (' + this.incompleteLogCount + ')';
         }
          ua.isComplete = this.logStateService.areAllLogsComplete();
        } else {
          ua.isComplete = this.treatmentPlanStateService.isUserActionComplete(ua, this.phase);
        }
      });

      this.userActions = this.sortByIsComplete(userActions);
      this.isSorted = true;
    }

    this.selectedSleepPrescription = this.treatmentPlanStateService.stateModel
      .get()
      .sleepPrescriptions?.find(p => p.phaseName === this.phase.name);
  }

  handleUserAction(ua: UserAction) {
    if (ua.userActionType === UserActionType.logs.name) {
      const hasActive = this.logStateService.hasActiveLog();
      if (!hasActive) {
        ua.route = '/logs/week';
      }
    }

    const isQuickStart = ua.userActionType === UserActionType.quickStart.name;
    const isReadItem = ua.userActionType === UserActionType.readItem.name; // Treatment week tactic items
    const isAcknowledge = ua.userActionType === UserActionType.acknowledge.name;
    const isActive = !ua.isComplete && !this.isPast && !ua.isExpired;

    if (isActive || isQuickStart || (isReadItem && !this.isPast) || isAcknowledge) {
      this.treatmentPlanStateService.handleUserAction(ua);
    }
  }

  sortByIsComplete(userActions: Array<any>): Array<any> {
    return userActions
      ? userActions.sort((a, b) => (a.isComplete === b.isComplete ? 0 : a.isComplete ? 1 : -1))
      : [];
  }
}
