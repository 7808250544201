import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '../../../clinician-portal/src/app/layout/layout.module';
import { OfflineHandlingGuard } from '@noctem/web';

const routes: Routes = [
{
  path: '',
  canActivate: [ OfflineHandlingGuard ],
  children: [
    { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) } ,
    { path: 'logs', loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule) },
    { path: 'coins', loadChildren: () => import('./coins/coins.module').then(m => m.CoinsModule) },
    { path: 'info', loadChildren: () => import('./dynamic-info/dynamic-info.module').then(m => m.DynamicInfoModule) },
    { path: 'stats', loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule) },
    { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule) },
    { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) },
    { path: 'bug', loadChildren: () => import('./report-bug/report-bug.module').then(m => m.ReportBugModule) },
    { path: 'sleep-info', loadChildren: () => import('./sleep-information/sleep-information.module').then(m => m.SleepInformationModule) },
    { path: 'history/:date/:type', loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) }
  ]
},

{ path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LayoutModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
