import { AssessmentDefinition } from "../../../../../noctem-lib/src/core/contracts/models.calculations";

export const postFieldSurveySoldier: AssessmentDefinition = {
  name: 'post-field-survey-soldier',
  description: 'Post field survey soldier',
  instructions: null,
  questions: [
    {
        id: 0,
        questionText: 'How often did you use the AIRE tool?',
        questionEmphasis: '',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_USE',
        autofill: false,
        answerOptions: [
            { display: 'Multiple times a day', value: 0 },
            { display: 'Once a day', value: 1 },
            { display: 'A couple of times per week', value: 2 },
            { display: 'Once a week', value: 3 },
            { display: 'Less than weekly', value: 4 },
            { display: 'Never', value: 5 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 2,
        questionText: 'How helpful did you find…',
        questionEmphasis: 'The AIRE tool',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_HELPFUL_AIRE',
        autofill: false,
        answerOptions: [
            { display: 'Not at all', value: 0 },
            { display: 'A little', value: 1 },
            { display: 'Somewhat', value: 2 },
            { display: 'Very much', value: 3 },
            { display: 'NA', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 7,
        questionText: 'How helpful did you find…',
        questionEmphasis: 'The AIRE tool to manage sleep and fatigue',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_MANAGE_FATIGUE',
        autofill: false,
        answerOptions: [
            { display: 'Not at all', value: 0 },
            { display: 'A little', value: 1 },
            { display: 'Somewhat', value: 2 },
            { display: 'Very much', value: 3 },
            { display: 'NA', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 16,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I think I would like to use the AIRE tool frequently.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_FREQUENT_USE',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 17,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I found the AIRE tool unnecessarily complex.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_UNNECESSARY',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 18,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I thought the AIRE tool was easy to use.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_EASY_USE',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 19,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I think that I would need the support of a technical person to be able to use the AIRE tool',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_TECH_PERSON',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 20,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I found the various functions in the AIRE tool well integrated.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_INTEGRATED',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 21,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I thought there was too much inconsistency in the AIRE tool.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_INCONSISTENCY',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 22,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I would imagine that most people would learn to use the AIRE tool very quickly.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_LEARN_QUICKLY',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 23,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I found the AIRE tool very cumbersome to use.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_CUMBERSOME',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 24,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I felt very confident using the AIRE tool.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_CONFIDENT_USE',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 25,
        questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
        questionEmphasis: 'I needed to learn a lot of things before I could get going with the AIRE tool.',
        questionType: 'radio',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_LEARN_LOTS',
        autofill: false,
        answerOptions: [
            { display: 'Strongly disagree', value: 0 },
            { display: 'Disagree', value: 1 },
            { display: 'Neither agree nor disagree', value: 2 },
            { display: 'Agree', value: 3 },
            { display: 'Strongly agree', value: 4 },
            { display: 'Prefer not to answer', value: 'N/A' },
        ],
        conditionalQuestions: [],
        isCheckInQuestion: false
    },
    {
        id: 39,
        questionText: 'Did you experience any barriers to using the AIRE tool? Select all that apply',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST_TRAIN_SOLDIER_EXPERIENCE_BARRIERS',
        autofill: false,
        answerOptions: [
            { display: 'Assessments took too long', value: 0 },
            { display: 'Issues with charging or power', value: 1 },
            { display: 'I didn’t understand how the tool works', value: 3 },
            { display: 'Leaders discouraged me from using', value: 4 },
            { display: 'Concerned about confidentiality', value: 5 },
            { display: 'The activities the tool recommended were not helpful to me', value: 6 },
            { display: 'Other, please specify', value: 7 },
            { display: 'Prefer not to answer', value: 'N/A', clearOtherAnswers: true },
        ],
        conditionalQuestions: [
          {
            id:1,
            questionText: 'Other',
            questionType: 'text',
            autofill: false,
            uniqueAnswerId: 'POST_TRAIN_SOLDIER_EXPERIENCE_BARRIERS_OTHER',
            answerOptions: [],
            displayIfAnswerEquals:[7],
          }
        ],
        isCheckInQuestion: false
    },
  ]
};
