import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { postFieldSurvey } from '../questions/training-survey/post-field-survey';
import { postFieldSurveyMedic } from '../questions/training-survey/post-field-survey-medic';

export const postFieldMedic: AssetDefinition = {
  id: 104, //change id to avoid conflict with other survey
  name: 'post-field-medic',
  label: 'Post field medic',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [
    postFieldSurvey, postFieldSurveyMedic
  ],
  url: 'post-field-medic'
}; 