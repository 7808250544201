
export class QRScanResult {
    public Value: string;
}


export class ControllerResult {
    public Id: string;
    public UnitId: string;
    public Version: string;
    public AppVersion: string;
    public OsVersion: string;
    public IrSensorEnabled: number;
    public QrCameraEnabled: number;
    public DevicePowered: number;
    public DriverTimeout: number;
    public UserTimeout: number;
}

type ErrorEvaluate = (err: any) => boolean;
interface ErrorFactory {
    evaluate: ErrorEvaluate;
    errorState: ErrorState;
}

// Frome native device we get "{"Code":404,"Message":"NOT_FOUND"}"
// If we try to connect to the wrong vehicle we get TypeError: Cannot read property 'MacAddress' of undefined

export class ErrorState {
    public static NotConnected: ErrorFactory =
        {
            evaluate: (err) => err && err.Code && err.Code === 400 && err.Message && err.Message === 'SCOOTER_NOT_CONNECTED',
            errorState: new ErrorState('NotConnected', 'Error communicating with the vehicle. Please connect to the vechicle.')
        };
    public static NotAuthenticated: ErrorFactory =
        {
            evaluate: (err) => err && err.Code && err.Message && err.Code === 500 && err.Message === 'Bad Request',
            errorState: new ErrorState('NotAuthenticated', 'Login authentication failed.')
        };

    public static Failed: ErrorFactory =
        {
            evaluate: (err) => err && err.Code && err.Message && err.Code === 500 && err.Message === 'ERROR',
            errorState: new ErrorState('Error', 'Error communicating with vehicle.')
        };
    public static ScannerFailure: ErrorFactory =
        {
            evaluate: (err) => err && err.Code && err.Message && err.Code === 500 && err.Message === 'FAILED_TO_LAUNCH_SCANNER',
            errorState: new ErrorState('Error', 'Error launching QR Scanner.')
        };
    public static All: ErrorFactory[] = [
        ErrorState.NotConnected,
        ErrorState.NotAuthenticated,
        ErrorState.Failed,
        ErrorState.ScannerFailure
    ];

    constructor(
        public Name: string,
        public Message: string
    ) { }
}

export class DeviceState {
    IsConnected: boolean;
    IsDisconnected: boolean;
    Errors: string;
}
