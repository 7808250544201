import { ThresholdIndices } from './threshold-indices';

export const sleepCheckInMap = [
  {
    name: 'sleepy-feels', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 2 },
      { threshold: ThresholdIndices.YELLOW, minValue: 3 },
      { threshold: ThresholdIndices.RED,  minValue: 4 }
    ]
  },
    {
    name: 'sleep-latency', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 31 },
      { threshold: ThresholdIndices.RED,  minValue: 45 }
    ]
  },
  {
    name: 'sleep-efficiency', thresholds: [
      { threshold: ThresholdIndices.RED, minValue: -100 },
      { threshold: ThresholdIndices.YELLOW, minValue: .7 },
      { threshold: ThresholdIndices.GREEN,  minValue: .85 }
    ]
  },
  {
    name: 'used-caffeine', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 401 },
      { threshold: ThresholdIndices.RED,  minValue: 600 }
    ]
  },
  {
    'slept-past-24': [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name:'total-sleep-past-24', thresholds: [
      { threshold: ThresholdIndices.RED, minValue: 0 * 60 },
      { threshold: ThresholdIndices.YELLOW, minValue: 5 * 60 + 1 },
      { threshold: ThresholdIndices.GREEN,  minValue: 7 * 60 + 1 }
    ]
  },
  {
    name: 'sleep-quality', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 2 },
      { threshold: ThresholdIndices.YELLOW, minValue: 3 },
      { threshold: ThresholdIndices.RED,  minValue: 4}
    ]
  },
  {
    name: 'caffeine-sources', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'caffeine-amount', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'sleep-longest-sleep', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  }
];
