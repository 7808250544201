import { Injectable }         from '@angular/core';
import { AssessmentQuestion } from '../services/models';

import { aceDaytimeSleepDef } from '../../../../aire/constant-definitions/modules/definitions/ace-daytime-sleep';
import { trainingFeedback } from '../../../../aire/constant-definitions/modules/definitions/training-feedback';
import { buildConfidenceDef } from '../../../../aire/constant-definitions/modules/definitions/build-confidence';
import { strategicNappingDef } from '../../../../aire/constant-definitions/modules/definitions/strategic-napping';
import { prioritizeThoughtsDef } from '../../../../aire/constant-definitions/modules/definitions/compartmentalization';
import { controlJetLagDef } from '../../../../aire/constant-definitions/modules/definitions/control-jet-lag';
import { dangersOfFatigueDef } from '../../../../aire/constant-definitions/modules/definitions/dangers-of-fatigue';
import { dreamRetrainingDef } from '../../../../aire/constant-definitions/modules/definitions/dream-retraining';
import { fatigueScanningDef } from '../../../../aire/constant-definitions/modules/definitions/fatigue-scanning';
import { gaugeSleepHealthDef } from '../../../../aire/constant-definitions/modules/definitions/gauge-sleep-health';
import { groundingDef } from '../../../../aire/constant-definitions/modules/definitions/grounding';
import { powerOfSleepDef } from '../../../../aire/constant-definitions/modules/definitions/power-of-sleep';
import { prepareThroughVisualizationDef } from '../../../../aire/constant-definitions/modules/definitions/prepare-through-visualization';
import { relaxationDef } from '../../../../aire/constant-definitions/modules/definitions/relaxation';
import { sleepCheckIn } from '../../../../aire/constant-definitions/modules/definitions/sleep-check-in';
import { stressCheckIn } from '../../../../aire/constant-definitions/modules/definitions/stress-check-in';
import { teamCheckIn } from '../../../../aire/constant-definitions/modules/definitions/team-check-in';
import { sleepAndHighTempoOpsDef } from '../../../../aire/constant-definitions/modules/definitions/sleep-and-high-tempo-ops';
import { sleepAndNightOpsDef } from '../../../../aire/constant-definitions/modules/definitions/sleep-and-night-ops';
import { sleepAreaSafeguardingDef } from '../../../../aire/constant-definitions/modules/definitions/sleep-area-safeguarding';
import { upYourSleepAreaDef } from '../../../../aire/constant-definitions/modules/definitions/up-your-sleep-area';
import { sleepBankingDef } from '../../../../aire/constant-definitions/modules/definitions/sleep-banking';
import { strategicCaffeineDef } from '../../../../aire/constant-definitions/modules/definitions/strategic-caffeine';
import { tacticalBreathingDef } from '../../../../aire/constant-definitions/modules/definitions/tactical-breathing';
import { teamCommunicationDef } from '../../../../aire/constant-definitions/modules/definitions/team-communication';
import { twoInternalSystemsDef } from '../../../../aire/constant-definitions/modules/definitions/two-internal-sleep-systems';
import { unwindBeforeBedDef } from '../../../../aire/constant-definitions/modules/definitions/unwind-before-bed';

const CONDITIONAL_Q_DELIMITER  = 36;
const SUB_ASSET_DELIMITER      = 37;
const ASSESSMENT_DELIMITER     = 38;
const TRANSFER_DELIMITER       = 39;
const MINIMUM_CHAR_CODE        = 40;
const LOG_TYPES = {
    'check-in': 1,
    'team-check-in': 2,
    'ace-daytime-sleep': 3,
    'training-feedback': 4,
    'build-confidence': 5,
    'strategic-napping': 6,
    'prioritize-thoughts': 7,
    'control-jet-lag': 8,
    'dangers-of-fatigue': 9,
    'dream-retraining': 10,
    'fatigue-scanning': 11,
    'gauge-sleep-health': 12,
    'grounding': 13,
    'power-of-sleep': 14,
    'prepare-through-visualization': 15,
    'relaxation': 16,
    'sleep-check-in': 17,
    'stress-check-in': 18,
    'sleep-and-high-tempo-ops': 19,
    'sleep-and-night-ops': 20,
    'sleep-area-safeguarding': 21,
    'up-your-sleep-area': 22,
    'sleep-banking': 23,
    'strategic-caffeine': 24,
    'tactical-breathing': 25,
    'team-communication': 26,
    'two-internal-sleep-systems': 27,
    'unwind-before-bed': 28,
}
const LOG_ID_TO_TYPE = {
    '1': 'check-in',
    '2': 'team-check-in',
    '3': 'ace-daytime-sleep',
    '4': 'training-feedback',
    '5': 'build-confidence',
    '6': 'strategic-napping',
    '7': 'prioritize-thoughts',
    '8': 'control-jet-lag',
    '9': 'dangers-of-fatigue',
    '10': 'dream-retraining',
    '11': 'fatigue-scanning',
    '12': 'gauge-sleep-health',
    '13': 'grounding',
    '14': 'power-of-sleep',
    '15': 'prepare-through-visualization',
    '16': 'relaxation',
    '17': 'sleep-check-in',
    '18': 'stress-check-in',
    '19': 'sleep-and-high-tempo-ops',
    '20': 'sleep-and-night-ops',
    '21': 'sleep-area-safeguarding',
    '22': 'up-your-sleep-area',
    '23': 'sleep-banking',
    '24': 'strategic-caffeine',
    '25': 'tactical-breathing',
    '26': 'team-communication',
    '27': 'two-internal-sleep-systems',
    '28': 'unwind-before-bed'
}
const LOG_TYPE_TO_ASSESSMENT = {
    '1': sleepCheckIn,
    '2': teamCheckIn,
    '3': aceDaytimeSleepDef,
    '4': trainingFeedback,
    '5': buildConfidenceDef,
    '6': strategicNappingDef,
    '7': prioritizeThoughtsDef,
    '8': controlJetLagDef,
    '9': dangersOfFatigueDef,
    '10': dreamRetrainingDef,
    '11': fatigueScanningDef,
    '12': gaugeSleepHealthDef,
    '13': groundingDef,
    '14': powerOfSleepDef,
    '15': prepareThroughVisualizationDef,
    '16': relaxationDef,
    '17': sleepCheckIn,
    '18': stressCheckIn,
    '19': sleepAndHighTempoOpsDef,
    '20': sleepAndNightOpsDef,
    '21': sleepAreaSafeguardingDef,
    '22': upYourSleepAreaDef,
    '23': sleepBankingDef,
    '24': strategicCaffeineDef,
    '25': tacticalBreathingDef,
    '26': teamCommunicationDef,
    '27': twoInternalSystemsDef,
    '28': unwindBeforeBedDef
}

const DATE_BYTE_LENGTH = 4;

@Injectable()
export class LogSerializer {

    constructor() {}

    public serializeLogs(userId: number, logs: any) {
        let cachedDates = Object.keys(logs);

        let serializedData = `${this.serializePatientId(userId)}`;
        for (let i = 0; i < cachedDates.length; i++) {
            let logDate = cachedDates[i];
            let logEntry = logs[logDate];
            let serializedLog = this.serializeLog(userId, logEntry);
            if (serializedLog) {
                serializedLog += String.fromCharCode(i < (cachedDates.length - 1) ? ASSESSMENT_DELIMITER : TRANSFER_DELIMITER);

                serializedData += serializedLog;
            }
        }
        return btoa(unescape(encodeURI(serializedData)));
    }

    public serializeSendModules(assessments) {
        let serializedData = String.fromCharCode(assessments.length + MINIMUM_CHAR_CODE);
        for (let assessment of assessments) {
            serializedData += String.fromCharCode(assessment.id + MINIMUM_CHAR_CODE);
            serializedData += String.fromCharCode(assessment.comment.length + MINIMUM_CHAR_CODE);
            serializedData += assessment.comment;
        }
        return serializedData;
    }

    public deserializeSendModules(serializedSendModules) {
        let deserializedModules = [];
        let numModules = (serializedSendModules.charCodeAt(0) - MINIMUM_CHAR_CODE);

        let i = 1;
        for (let moduleIndex = 0; moduleIndex < numModules; moduleIndex++) {
            let moduleId = serializedSendModules.charCodeAt(i) - MINIMUM_CHAR_CODE;
            i++

            let moduleLength = serializedSendModules.charCodeAt(i) - MINIMUM_CHAR_CODE
            i++;

            let moduleData = serializedSendModules.substr(i, moduleLength);
            i += moduleLength;

            deserializedModules.push({id: moduleId, comment: moduleData})
        }

        return deserializedModules;
    }

    private serializeLog(userId: number, logEntry) {
        let logType = logEntry['logType'];
        let questions = logEntry['questions'];
        let logDate = logEntry['logDate'];
        let logStartTime = logEntry['startTime'];
        let logEndTime = logEntry['endTime'];
        let serializedDates = `${this.serializeDate(logDate)}${this.serializeDate(logStartTime)}${this.serializeDate(logEndTime)}`
        let logTypeId = LOG_TYPES[logType];
        let logTypeSerialized = String.fromCharCode(LOG_TYPES[logType] + MINIMUM_CHAR_CODE);
        let serializedData = `${logTypeSerialized}${serializedDates}`;
        let currentAssessmentIndex = 0;
        if(!logType || !logTypeId || !questions) { return null; }
        for (let question of questions) {
          if(question) {
            let originalQuestionMeta = this.findOriginalQuestionMeta(logTypeId, question.uniqueAnswerId);
            if (originalQuestionMeta && originalQuestionMeta.assessmentIndex != currentAssessmentIndex) {
                serializedData += String.fromCharCode(SUB_ASSET_DELIMITER);
                currentAssessmentIndex += 1;
            }

            if (question?.answer != undefined) {
                let serializedAnswer = this.serializeAnswer(question.answer, question.questionType);
                if (serializedAnswer) {
                    serializedData += String.fromCharCode(question.id + MINIMUM_CHAR_CODE);
                    serializedData += String.fromCharCode(serializedAnswer.length + MINIMUM_CHAR_CODE);
                    serializedData += serializedAnswer;
                }
            } else if (question?.multipleQuestionArray) {
                serializedData += String.fromCharCode(question.id + MINIMUM_CHAR_CODE);
                for (let subQuestion of question.multipleQuestionArray) {
                    let serializedAnswer = this.serializeAnswer(subQuestion.answer, question.questionType);
                    serializedData += String.fromCharCode(subQuestion.id + MINIMUM_CHAR_CODE);
                    serializedData += String.fromCharCode(serializedAnswer.length + MINIMUM_CHAR_CODE);
                    serializedData += serializedAnswer;
                }
            } else {
                let serializedAnswer = this.serializeAnswer('T', question.questionType);
                serializedData += String.fromCharCode(question.id + MINIMUM_CHAR_CODE);
                serializedData += String.fromCharCode(1 + MINIMUM_CHAR_CODE);
                serializedData += serializedAnswer;
            }

            let serializedConditionalQuestions = this.serializeConditionalQuestions(question);
            if (serializedConditionalQuestions) {
                serializedData += serializedConditionalQuestions;
            }

          }
        }
        return serializedData;
    }

    private serializeConditionalQuestions(question, serializedInitial = ""): string {

        let serializedConditionalQuestions = serializedInitial;
        if (question.conditionalQuestions && question.conditionalQuestions.length > 0) {
            let answeredQuestions = question.conditionalQuestions.filter(q => {
                if (q.answer && Array.isArray(q.answer) && q.questionType === 'checkbox') {
                    return q.answer.filter(colQ => colQ.isChecked === true).length > 0;
                } else {
                    return q.answer !== undefined
                }
            })

            if (answeredQuestions.length > 0) {
                serializedConditionalQuestions += String.fromCharCode(CONDITIONAL_Q_DELIMITER);
                serializedConditionalQuestions += String.fromCharCode(answeredQuestions.length + MINIMUM_CHAR_CODE);
                for (let conditionalQuestion of answeredQuestions) {
                    let cSerializedAnswer = this.serializeAnswer(conditionalQuestion.answer, conditionalQuestion.questionType);
                    serializedConditionalQuestions += String.fromCharCode(conditionalQuestion.id + MINIMUM_CHAR_CODE);
                    serializedConditionalQuestions += String.fromCharCode(cSerializedAnswer.length + MINIMUM_CHAR_CODE);
                    serializedConditionalQuestions += cSerializedAnswer;
                    
    
                    if (conditionalQuestion.conditionalQuestions && conditionalQuestion.conditionalQuestions.length > 0) {
                        let serializedSubQuestions = this.serializeConditionalQuestions(conditionalQuestion, serializedConditionalQuestions);
                        serializedConditionalQuestions = serializedSubQuestions;
                    }
                }
            }
        }
        return serializedConditionalQuestions;
    }

    private findOriginalQuestionMeta(logTypeId, uniqAnswerId) {
        let originalQuestion = null;
        let originalAssessment = LOG_TYPE_TO_ASSESSMENT[logTypeId];
        if (originalAssessment && originalAssessment.assessments) {
            for (let i = 0; i < originalAssessment.assessments.length; i++) {
                let foundAssessment = originalAssessment.assessments[i].questions.find(t => t.uniqueAnswerId == uniqAnswerId);
                if (foundAssessment) {
                    return { assessmentIndex: i };
                }
            }
        }
        return originalQuestion;
    }

    private serializeAnswer(answer: any, questionType: string): string {
        if (answer === null || answer === undefined) {
            return "";
        }

        switch (typeof answer) {
            case 'boolean':
                return (answer ? "T" : "F");
            case 'string':
                if (questionType === "time" || questionType === "duration") {
                    return answer;
                } else {
                    return String.fromCharCode(parseInt(answer) + MINIMUM_CHAR_CODE);
                }
            case 'object':
                if (Array.isArray(answer)) {
                    if (questionType === 'checkbox') {
                        let mappedResponse = answer.map(a => {
                            return a.isChecked ? String.fromCharCode(parseInt(a.value) + MINIMUM_CHAR_CODE) : ''
                        });
                        return mappedResponse.join("");
                    } else {
                        return answer.map(a => String.fromCharCode(parseInt(a.value) + MINIMUM_CHAR_CODE)).join("");
                    }
                } else if (questionType === 'column') {
                    return answer['value'];
                } else {
                    return String.fromCharCode(parseInt(answer['value']) + MINIMUM_CHAR_CODE);
                }
            case 'number':
                if (typeof answer === 'number') {
                    return String.fromCharCode(answer + MINIMUM_CHAR_CODE);
                } else {
                    return answer;
                }
            default:
                return ' '
        }
    }

    public deserialize(serializedData: string) {
        let firstPaddingIndex = serializedData.indexOf(",");
        let choppedSerializedData = firstPaddingIndex > 0 ? serializedData.substring(0, firstPaddingIndex) : serializedData;
        choppedSerializedData = decodeURIComponent(escape(atob(choppedSerializedData)));
        let patientId = this.deserializePatientId(choppedSerializedData.substr(0, 3));
        let i = 3;
        let hitEndOfAssessment      = false;
        let hitEndOfTransfer        = false;
        let assessments             = [];
        let currentAssessmentIndex  = 0;

        while (!hitEndOfTransfer && i < choppedSerializedData.length) {
            currentAssessmentIndex = 0;
            let logTypeId = (choppedSerializedData.charCodeAt(i) - MINIMUM_CHAR_CODE).toString();
            i += 1;
            let logType = LOG_ID_TO_TYPE[logTypeId]
            let logDate = this.deserializeDate(choppedSerializedData.substr(i, DATE_BYTE_LENGTH));
            i += DATE_BYTE_LENGTH;
            let startTime = this.deserializeDate(choppedSerializedData.substr(i, DATE_BYTE_LENGTH));
            i += DATE_BYTE_LENGTH;
            let endTime = this.deserializeDate(choppedSerializedData.substr(i, DATE_BYTE_LENGTH));
            i += DATE_BYTE_LENGTH;
            

            let questions = [];
            while (!hitEndOfAssessment && i < choppedSerializedData.length) {
                let id = choppedSerializedData.charCodeAt(i) - MINIMUM_CHAR_CODE;
                let foundQuestion = this.getQuestionForId(logTypeId, id, currentAssessmentIndex);
                try {
                    let question: AssessmentQuestion = JSON.parse(JSON.stringify(foundQuestion));
                    if (question.questionType === 'multiple') {
                        let questionData = this.fillMultiplePartQuestion(question, choppedSerializedData.substring(i + 1, choppedSerializedData.length));
                        questions.push(questionData.question)
                        i += 1 + questionData.dataLength;
                    } else {
                        let dataLength = choppedSerializedData.charCodeAt(i + 1) - MINIMUM_CHAR_CODE;
                        let serializedAnswer = choppedSerializedData.substr(i + 2, dataLength);
                        let answeredQuestion = this.fillAnswers(question, serializedAnswer);
                        i += 2 + dataLength;

                        if (question) {
                            questions.push(answeredQuestion)
                        }
                    }

                    if (choppedSerializedData.charCodeAt(i) === CONDITIONAL_Q_DELIMITER) {
                        i += 1;
                        i = this.deserializeConditionalQuestions(choppedSerializedData, i, question.conditionalQuestions);
                    }

                    if (choppedSerializedData.charCodeAt(i) === TRANSFER_DELIMITER) {
                        hitEndOfTransfer = true;
                        hitEndOfAssessment = true;
                        i += 1;
                    } else if (choppedSerializedData.charCodeAt(i) === ASSESSMENT_DELIMITER) {
                        hitEndOfAssessment = true;
                        i += 1;
                    }

                    if (choppedSerializedData.charCodeAt(i) === SUB_ASSET_DELIMITER) {
                        currentAssessmentIndex += 1;
                        i += 1;
                    }
                } catch (e) {
                    console.log(JSON.stringify(e));
                    i += 1;
                }
            }
            assessments.push({ logType, questions, logDate, patientId, startTime, endTime })

            hitEndOfAssessment = false;
        }
        return { assessments, patientId };
    }

    private deserializeConditionalQuestions(serializedData: string, currentIndex = 0, questions): any {
        let index = currentIndex;
        let numQuestions = serializedData.charCodeAt(currentIndex) - MINIMUM_CHAR_CODE;
        index += 1;
        for (let questionIndex = 0 ; questionIndex < numQuestions; questionIndex++) {
            let questionId = serializedData.charCodeAt(index) - MINIMUM_CHAR_CODE;
            index += 1;
            let answerLength = serializedData.charCodeAt(index) - MINIMUM_CHAR_CODE;
            index += 1;
            let answer = serializedData.substr(index, answerLength);
            index += answerLength;


            let foundQuestion = questions.find(q => q.id === questionId);
            this.fillAnswers(foundQuestion, answer);

            if (serializedData.length > index && serializedData.charCodeAt(index) === CONDITIONAL_Q_DELIMITER) {
                index += 1;
                index = this.deserializeConditionalQuestions(serializedData, index, foundQuestion.conditionalQuestions)
            }
        }
        return index;
    }

    private cloneObject(obj: any) {
        return JSON.parse(JSON.stringify(obj))
    }

    private fillAnswers(question: AssessmentQuestion, answer: string) {
        switch (question.questionType) {
            case 'checkbox':
                let answers = [];
                for (let answerOption of question.answerOptions) {
                    let mappedAnswer = String.fromCharCode(parseInt(answerOption.value) + MINIMUM_CHAR_CODE);
                    let answerObj = this.cloneObject(answerOption);
                    if (mappedAnswer) {
                        answerObj.isChecked = answer.includes(mappedAnswer.toString())
                    }
                    answers.push(answerObj);
                }
                question.answer = answers;
                break;
            case 'radio':
                let sampleAnswer = question.answerOptions.length > 0 ? question.answerOptions[0].value : 'text';
                if (typeof sampleAnswer === 'boolean') {
                    question.answer = answer === 'T';
                } else {
                    question.answer = answer.charCodeAt(0) - MINIMUM_CHAR_CODE;
                }
                break;
            case 'object':
                if (Array.isArray(answer)) {
                    question.answer = answer.map(a => String.fromCharCode(a.value + MINIMUM_CHAR_CODE)).join("");
                } else {
                    question.answer = String.fromCharCode(parseInt(answer['value']) + MINIMUM_CHAR_CODE);
                }
                break;
            case 'number':
                question.answer = (answer.charCodeAt(0) - MINIMUM_CHAR_CODE).toString();
                break;
            case 'slider':
                question.answer = answer.charCodeAt(0) - MINIMUM_CHAR_CODE;
                break;
            case 'module':
                question.answer = true;
                break;
            case 'time':
            case 'duration':
                question.answer = answer;
                break;

            }
        return question;
    }

    private fillMultiplePartQuestion(question: AssessmentQuestion, answerData: string) {
        let answeredQuestions = 0;
        let numQuestions = question.multipleQuestionArray.length;
        let i = 0;

        while (answeredQuestions < numQuestions) {
            let questionId = answerData.charCodeAt(i) - MINIMUM_CHAR_CODE;
            let dataLength = answerData.charCodeAt(i + 1) - MINIMUM_CHAR_CODE;
            let serializedAnswer = answerData.substr(i + 2, dataLength);

            question.multipleQuestionArray[answeredQuestions] = this.fillAnswers(question.multipleQuestionArray[answeredQuestions], serializedAnswer);
            i += 2 + dataLength;
            answeredQuestions += 1;
        }
        return { question, dataLength: i };
    }

    private getQuestionForId(logType: string, questionId: number, currentAssessmentIndex: number) {
        let assessment = LOG_TYPE_TO_ASSESSMENT[logType];
        if (assessment && assessment.assessments) {
            if (assessment.assessments[currentAssessmentIndex]) {
                let questions = assessment.assessments[currentAssessmentIndex].questions;
                let question = questions?.find(question => question.id === questionId);
                if (question) {
                    return question;
                }
            }
        }
    }


    // DATE PACKING:
    // 4 Bytes packed
    private serializeDate(dateString: any) {
        let date = Math.floor(new Date(dateString).getTime()/1000);

        let b1 = (date & 0xFF)
        let b2 = ((date >> 8) & 0xFF)
        let b3 = ((date >> 16) & 0xFF)
        let b4 = ((date >> 24) & 0xFF)

        let serializedDate = `${String.fromCharCode(b1 + MINIMUM_CHAR_CODE)}${String.fromCharCode(b2 + MINIMUM_CHAR_CODE)}${String.fromCharCode(b3 + MINIMUM_CHAR_CODE)}${String.fromCharCode(b4 + MINIMUM_CHAR_CODE)}`;
        return serializedDate;
    }

    private deserializeDate(serializedDate: string) {
        let byte1 = (serializedDate.charCodeAt(0) - MINIMUM_CHAR_CODE);
        let byte2 = (serializedDate.charCodeAt(1) - MINIMUM_CHAR_CODE);
        let byte3 = (serializedDate.charCodeAt(2) - MINIMUM_CHAR_CODE);
        let byte4 = (serializedDate.charCodeAt(3) - MINIMUM_CHAR_CODE);

        let unpacked = (byte1 + (byte2 << 8) + (byte3 << 16) + (byte4 << 24));
        return new Date(unpacked * 1000);
    }

    public isSerializeable(logType: string) {
        return LOG_TYPES[logType];
    }

    private serializePatientId(patientId: number) {
        let serializedId = '';
        serializedId += String.fromCharCode((patientId & 0x0000FF) + MINIMUM_CHAR_CODE);
        serializedId += String.fromCharCode(((patientId & 0x00FF00) >> 8)  + MINIMUM_CHAR_CODE);
        serializedId += String.fromCharCode(((patientId & 0xFF0000) >> 16) + MINIMUM_CHAR_CODE);
        return serializedId;
    }

    private deserializePatientId(serializedId: string) {
        let byte1 = (serializedId.charCodeAt(0) - MINIMUM_CHAR_CODE);
        let byte2 = (serializedId.charCodeAt(1) - MINIMUM_CHAR_CODE) << 8;
        let byte3 = (serializedId.charCodeAt(2) - MINIMUM_CHAR_CODE) << 16;
        return (byte1 | byte2 | byte3);
    }
}
