import { Injectable, Inject, OnDestroy } from '@angular/core';
import { ApplicationStateService, UserStateService, ChatStateService, NotificationStateService, GameStateService, NoctemUser, LogStateService, TreatmentPlanStateService, AssessmentTemplate, USER_STATE_SERVICE } from '@noctem/web';
import { take, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { APPLICATION_ORGANIZATION } from '../../../../../noctem-lib/src/constants/constants';
import { GroupService } from '../../../../../noctem-lib/src/lib/services';

@Injectable({
  providedIn: 'root'
})
export class ServiceBus implements OnDestroy {
  private isInitialized = false;
  destroy$: Subject<any> = new Subject();
  constructor(
    private appStateService: ApplicationStateService,
    private notificationStateService: NotificationStateService,

    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
    private chatStateService: ChatStateService,
    private gameStateService: GameStateService,
    private logStateService: LogStateService,
    private groupService: GroupService,
    private treatmentPlanStateService: TreatmentPlanStateService,
    private router: Router,

  ) { }


  public initialize() {

    this.userStateService.onUserLoaded$.pipe(
      filter(u => (u as any).IsLoggedIn),
      take(1)
    ).subscribe((userState) => {
      // WAYAN:  To Do:  Persist device id for use in sending notifications
      // You can handle this a few ways; I was planning on saving this on the UserSettings object
      const user =  userState.User as any as NoctemUser;
      const userId = userState.User.UserId;
      this.chatStateService.initialize(userId);
      this.gameStateService.initialize(user);

      this.notificationStateService.initialize({
        id: userId,
        model: 'UserSettings',
        display: `${user.profile.firstName} ${user.profile.lastName}`
      });
      this.groupService.getAll().pipe(
        takeUntil(this.destroy$)
        ).subscribe(groups => {
        const userGroup = user.groups?.find(g => g.id !== APPLICATION_ORGANIZATION._id);
        const group = groups.find(g => g.__i.guid === userGroup.id);
        const isResearch = group?.isResearch;
        const isConsentRequired = group?.isConsentRequired;
        if(user.profile.isActive === false) {
          localStorage.clear();
        }
        else if(!user.applicationData || !user.applicationData.eulaAgreedOn) {
          this.router.navigateByUrl('info/eula/' + (group.eulaId ?? 0));
        }
        else if ((isResearch || isConsentRequired) && !user.applicationData.consentObtainedOn) {
          this.router.navigateByUrl('info/consent/' + (group.consentId ?? 0));
        }
        else{
          this.router.navigateByUrl('/');
        }
      });
    });

    this.chatStateService.onMessageReceived$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(() => {
      // If we're not in the chat page, increment chat alert
      if (this.router.url.indexOf('chat') < 0) {
        this.appStateService.addAlert('chat');
      }
    });

    this.notificationStateService.onNotificationAdded$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(() => {
      // If we're not in the chat page, increment chat alert
      if (this.router.url.indexOf('notifications') < 0) {
        this.appStateService.addAlert('notifications');
      }
    });

    this.treatmentPlanStateService.onTotalPoints$.pipe(
      takeUntil(this.destroy$)
      ).subscribe((isRead: boolean) => {
      // Create a new notification
      const points = this.gameStateService.stateModel.get().patientPoints;
      if (points && points !== 0) {
        this.notificationStateService.add('earnedPoints', points.toString(), isRead);
      }
    });

    this.gameStateService.onCoinAchieved$.pipe(
      takeUntil(this.destroy$)
        ).subscribe(coin => {
      // Create a new notification
      this.notificationStateService.add('newCoin', coin.name);
    });

    this.logStateService.onLogStateLoaded$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(logType => {
      const type = logType === AssessmentTemplate.eveningLog.name ? 'activeEveningLog' : 'activeMorningLog';
      //this.notificationStateService.add(type);
    });

    this.logStateService.onLogCompleted$.pipe(
      takeUntil(this.destroy$)
        ).subscribe(logState => {
      this.notificationStateService.markAsRead();
    });

    this.treatmentPlanStateService.onCurrentSleepPrescription$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(sleepRx => {
      this.logStateService.setSleepPrescriptionQuestions(sleepRx);
      //this.notificationStateService.add('sleepRecommendation', null, !!sleepRx.notificationReadOn);

    });

    this.notificationStateService.earnedPointsRead$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(async (isRead) => {
      await this.treatmentPlanStateService.calendarEventNotificationRead();
    });

    this.notificationStateService.sleepRecommendationRead$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(async (isRead) => {
      await this.treatmentPlanStateService.sleepPrescriptionNotificationRead();
    });

    return this.appStateService.initialize();
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
