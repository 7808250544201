import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';

export const postFieldSurvey: AssessmentDefinition = {
  name: 'post-field-survey',
  description: 'End Evaluation Survey',
  instructions: null,
  questions: [
    {
      id: 32,
      questionText: 'Please complete no earlier than one day prior to graduation or upon leaving the course.<br><br>',
      questionEmphasis:
        'On average, how sleepy did you feel at the beginning of your duty day while at the training?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_DAY_BEGIN',
      autofill: false,
      answerOptions: [
        { display: 'Extremely sleepy, fighting sleep', value: 0 },
        { display: 'Sleepy, some effort to keep alert', value: 1 },
        { display: 'Sleepy, but no difficulty remaining awake', value: 2 },
        { display: 'Some signs of sleepiness', value: 3 },
        { display: 'Neither alert nor sleepy', value: 4 },
        { display: 'Rather Alert', value: 5 },
        { display: 'Alert', value: 6 },
        { display: 'Very alert', value: 7 },
        { display: 'Extremely alert', value: 8 },
        { display: 'Prefer not to answer', value: 9 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 33,
      questionText: 'On average, how sleepy…',
      questionEmphasis:
        'Did you feel right before you had an opportunity to nap or sleep while at the training?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_NAP_OPPORTUNITY',
      autofill: false,
      answerOptions: [
        { display: 'Extremely sleepy, fighting sleep', value: 0 },
        { display: 'Sleepy, some effort to keep alert', value: 1 },
        { display: 'Sleepy, but no difficulty remaining awake', value: 2 },
        { display: 'Some signs of sleepiness', value: 3 },
        { display: 'Neither alert nor sleepy', value: 4 },
        { display: 'Rather Alert', value: 5 },
        { display: 'Alert', value: 6 },
        { display: 'Very alert', value: 7 },
        { display: 'Extremely alert', value: 8 },
        { display: 'Prefer not to answer', value: 9 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 34,
      questionText: 'On average, how sleepy…',
      questionEmphasis: 'Do you feel right now?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_CURRENT_FEEL',
      autofill: false,
      answerOptions: [
        { display: 'Extremely sleepy, fighting sleep', value: 0 },
        { display: 'Sleepy, some effort to keep alert', value: 1 },
        { display: 'Sleepy, but no difficulty remaining awake', value: 2 },
        { display: 'Some signs of sleepiness', value: 3 },
        { display: 'Neither alert nor sleepy', value: 4 },
        { display: 'Rather Alert', value: 5 },
        { display: 'Alert', value: 6 },
        { display: 'Very alert', value: 7 },
        { display: 'Extremely alert', value: 8 },
        { display: 'Prefer not to answer', value: 9 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 39,
      questionText:
        'During the training, on average, how many caffeinated beverages do you consume on a daily basis? (One caffeinated beverage is equal to one 8oz cup of coffee, one 8oz energy drink or 16oz of tea)',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_CAFFEINE_2',
      autofill: false,
      answerOptions: [
        // eslint-disable-next-line prettier/prettier
        { display: 'None-I don\'t drink caffeinated beverages', value: 0 },
        { display: 'Less than once a week', value: 1 },
        { display: 'At least once a week, but not everyday', value: 2 },
        { display: '1 per day', value: 3 },
        { display: '2 per day', value: 4 },
        { display: '3 per day', value: 5 },
        { display: '4 per day', value: 6 },
        { display: '5 or more per day', value: 7 },
        { display: 'Prefer not to answer', value: 8 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    // TODO: Swap questionEmphasis for questionText?
    {
      id: 53,
      questionText:
        'The following questions relate to your experience with your team during the training:',
      questionEmphasis:
        'How often do members of your team disagree about opinions regarding the work being done?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_DISAGREE_OPINIONS',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Very often', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 54,
      questionText:
        'The following questions relate to your experience with your team during the training:',
      questionEmphasis: 'How frequently are there conflicts about ideas in your team?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_TEAM_CONFLICTS',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Very often', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 55,
      questionText:
        'The following questions relate to your experience with your team during the training:',
      questionEmphasis: 'How frequently are there personality conflicts evident in your team?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_PERSONALITY_CONFLICTS',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Very often', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 56,
      questionText:
        'The following questions relate to your experience with your team during the training:',
      questionEmphasis: 'How often is there tension among Soldiers in your team?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_TENSION',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Very often', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 57,
      questionText:
        'The following questions relate to your experience with your team during the training:',
      questionEmphasis: 'How often is there conflict in your crew about task responsibilities?',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_CREW_CONFLICT',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Rarely', value: 1 },
        { display: 'Sometimes', value: 2 },
        { display: 'Often', value: 3 },
        { display: 'Very often', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 58,
      questionText:
        'Tell us how much you DISAGREE or AGREE with the statements below thinking about the time at the training.',
      questionEmphasis: 'The members of my team are cooperative with each other.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_TEAM_COOPERATIVE',
      autofill: false,
      answerOptions: [
        { display: 'Strongly disagree', value: 0 },
        { display: 'Disagree', value: 1 },
        { display: 'Neither Agree nor Disagree', value: 2 },
        { display: 'Agree', value: 3 },
        { display: 'Strongly agree', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 59,
      questionText:
        'Tell us how much you DISAGREE or AGREE with the statements below thinking about the time at the training.',
      questionEmphasis: 'The members of my team know that they can depend on each other.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_TEAM_DEPEND',
      autofill: false,
      answerOptions: [
        { display: 'Strongly disagree', value: 0 },
        { display: 'Disagree', value: 1 },
        { display: 'Neither Agree nor Disagree', value: 2 },
        { display: 'Agree', value: 3 },
        { display: 'Strongly agree', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 60,
      questionText:
        'Tell us how much you DISAGREE or AGREE with the statements below thinking about the time at the training.',
      questionEmphasis: 'The members of my team stand up for each other.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_TEAM_STAND',
      autofill: false,
      answerOptions: [
        { display: 'Strongly disagree', value: 0 },
        { display: 'Disagree', value: 1 },
        { display: 'Neither Agree nor Disagree', value: 2 },
        { display: 'Agree', value: 3 },
        { display: 'Strongly agree', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 61,
      questionText:
        'Thinking about the time at the training, rate how often each of the statements is true for you.',
      questionEmphasis: 'I find it easy to work as part of the team.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_EASY_TEAM',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Occasionally', value: 1 },
        { display: 'About half of the time', value: 2 },
        { display: 'Most of the time', value: 3 },
        { display: 'Almost always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 62,
      questionText:
        'Thinking about the time at the training, rate how often each of the statements is true for you.',
      questionEmphasis: 'The members of my team have been communicating well with each other.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_COMMUNICATE_WELL',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Occasionally', value: 1 },
        { display: 'About half of the time', value: 2 },
        { display: 'Most of the time', value: 3 },
        { display: 'Almost always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 63,
      questionText:
        'Thinking about the time at the training, rate how often each of the statements is true for you.',
      questionEmphasis: 'I feel comfortable expressing my thoughts to my fellow teammates.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_EXPRESS_THOUGHTS',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Occasionally', value: 1 },
        { display: 'About half of the time', value: 2 },
        { display: 'Most of the time', value: 3 },
        { display: 'Almost always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 64,
      questionText:
        'Thinking about the time at the training, rate how often each of the statements is true for you.',
      questionEmphasis: 'I find myself getting angry with people or situations.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_ANGRY_SITUATIONS',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Occasionally', value: 1 },
        { display: 'About half of the time', value: 2 },
        { display: 'Most of the time', value: 3 },
        { display: 'Almost always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 65,
      questionText:
        'Thinking about the time at the training, rate how often each of the statements is true for you.',
      questionEmphasis: 'If a conflict arises, I am able to handle myself appropriately.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_HANDLE_APPROPRIATELY',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Occasionally', value: 1 },
        { display: 'About half of the time', value: 2 },
        { display: 'Most of the time', value: 3 },
        { display: 'Almost always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 66,
      questionText:
        'Thinking about the time at the training, rate how often each of the statements is true for you.',
      questionEmphasis: 'I feel left out and isolated.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_ISOLATED',
      autofill: false,
      answerOptions: [
        { display: 'Never', value: 0 },
        { display: 'Occasionally', value: 1 },
        { display: 'About half of the time', value: 2 },
        { display: 'Most of the time', value: 3 },
        { display: 'Almost always', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 78,
      questionText:
        'How much difficulty did you have with the following during the training?',
      questionEmphasis: 'The quality of your work',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_QUALITY_WORK',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 79,
      questionText:
        'How much difficulty did you have with the following during the training?',
      questionEmphasis: 'The accuracy of your work',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_ACCURACY_WORK',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 80,
      questionText:
        'How much difficulty did you have with the following during the training?',
      questionEmphasis: 'Your ability to complete assigned tasks',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_COMPLETE_TASKS',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 81,
      questionText:
        'How much difficulty did you have with the following during the training?',
      questionEmphasis: 'Your overall work performance',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_WORK_PERFORMANCE',
      autofill: false,
      answerOptions: [
        { display: 'No difficulty', value: 0 },
        { display: 'A little difficulty', value: 1 },
        { display: 'Moderate difficulty', value: 2 },
        { display: 'Quite a bit of difficulty', value: 3 },
        { display: 'Extreme difficulty', value: 4 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
