import {
    ControllerResult, DeviceState, ErrorState
} from './device-models';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { Model, ModelFactory } from '@noctem/web';
//import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';

@Injectable({
    providedIn: 'root',
})
export class DeviceService implements OnDestroy {

    public stateModel: Model<DeviceState>;
    public state$: Observable<DeviceState>;
    public scanSub: Subscription;

    public onSubscriptionsInitialized$: BehaviorSubject<any> = new BehaviorSubject(false);
    public onDeviceNotification$: Subject<any> = new Subject();

    public onNotification$: Subject<any> = new Subject();
    public onScannerInput$: Subject<any> = new Subject();
    destroy$: Subject<any> = new Subject();

    constructor(
        stateFactory: ModelFactory<DeviceState>,
        //private qrScanner: QRScanner
    ) {
        this.stateModel = stateFactory.create(new DeviceState());
        this.state$ = this.stateModel.data$.pipe(map(items => items));
        //this.qrScanner = qrScanner;
    }

    public initSubscriptions(): void {
        setTimeout(() => {
            if (!this.isNative()) {
                return;
            }

            //TODO: How does this trigger? Is it still used? 
            this.onNotification$.pipe(
                catchError((err) => this.mapError(err)),
            )
                .subscribe(
                    (res: any) => {
                        if (res instanceof ErrorState) {

                        } else if (res && res.Data) {
                            const message = res.Data;
                            this.onDeviceNotification$.next(message);
                        }
                    }
                );


            this.onSubscriptionsInitialized$.next(true);
        }, 500);

    }

    public getInfo(): Observable<ControllerResult> {
        return this.makeCall();
    }

    /*
    public scanCode(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.qrScanner.prepare()
            .then((status: QRScannerStatus) => {
                if (status.authorized) {

                    this.scanSub = this.qrScanner.scan().subscribe((text: string) => {
                    if (text && text.length === 6) {
                        resolve({ Value: text });
                    } else {
                        reject('Failed to scan QR code.')
                    }
    
                    this.setDocumentVisibile(true);
                    this.qrScanner.hide();
                    this.scanSub.unsubscribe();
                });

                this.qrScanner.show().then(() => {
                    // QR library assumes no background image, so it must be hidden
                    this.setDocumentVisibile(false);
                });

            } else if (status.denied) {
                reject();
            } else {
                reject();
            }
            })
            .catch((e: any) => console.log('Error is', e));
        });
    }
    
    public cancelScan() {
        if (this.scanSub) {
            this.scanSub.unsubscribe();
        }
        this.setDocumentVisibile(true);
        this.qrScanner.destroy();
    }
    */
    private setDocumentVisibile(isVisible: boolean) {
        document.getElementsByTagName("body")[0].style['background-size'] = isVisible ? '100% 100%' : '0 0';
    }

    public isNative(): boolean {
        return false;
    }

    private mapError(err: any): Observable<any> {
        let error = ErrorState.All.find((es) => es.evaluate(err));
        // If it isn't a known error, default to a generic error message
        if (error === undefined) {
            error = ErrorState.Failed;
        }
        return of(error.errorState);
    }

    private makeCall(): Observable<any> {
        return Observable.create(() => {
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
