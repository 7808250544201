import { ThresholdIndices } from './threshold-indices';

export enum gad7Threshold {
  NONE = 0,
  GREEN = 1,
  YELLOW = 2,
  RED = 3
}

export const gad7ThresholdMap = [
  {
    name: 'gad7-overall', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 5 },
      { threshold: ThresholdIndices.RED,  minValue: 10 }
    ]
  },
  {
    name: 'gad7-nervous', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'gad7-worry', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name:'gad7-worry-too-much', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'gad7-trouble-relaxing', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'gad7-restless', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'gad7-irritable', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]
  },
  {
    name: 'gad7-afraid', thresholds: [
      { threshold: ThresholdIndices.GREEN, minValue: 0 },
      { threshold: ThresholdIndices.YELLOW, minValue: 1 },
      { threshold: ThresholdIndices.RED,  minValue: 2 }
    ]

  }];
