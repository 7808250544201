import { UserService } from './user.service';
import { UserSettings, Role, IApplicationContext, Organization } from '../../../core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheService } from '../../services/cache-service';
import { NetworkService } from '../../services/network-service';

let CURRENT_USER_KEY      = "CURRENT_USER_KEY";
let CURRENT_USER_INFO_KEY = "CURRENT_USER_INFO_KEY";

export class CachedUserDecorator<T extends UserSettings> extends UserService<T> {

    constructor(appContext: IApplicationContext,
                private cacheService: CacheService,
                private networkService: NetworkService) {
        super(appContext);
    }

    public getCurrentUser(): Observable<T> {
        if (this.networkService.isOnline()) {
            return super.getCurrentUser().pipe(
                tap(data => {
                    this.cacheService.set(CURRENT_USER_KEY, data)
                    return data;
                }))
        } else {
            return new Observable(subscriber => {
                subscriber.next(this.cacheService.get(CURRENT_USER_KEY) as T)
                subscriber.complete();
            });
        }
    }

    public getCurrentUserInfo(): Observable<{ User: T, Roles: Role[], Organizations: Organization[] }> {
        if (this.networkService.isOnline()) {
            return super.getCurrentUserInfo().pipe(
                tap(data => {
                    this.cacheService.set(CURRENT_USER_INFO_KEY, data)
                    return data;
                }));
        } else {
            return new Observable(subscriber => {
                subscriber.next(this.cacheService.get(CURRENT_USER_INFO_KEY) as { User: T, Roles: Role[], Organizations: Organization[] })
                subscriber.complete();
            })
        }
    }

    public updateNotificationsToken(token: string): Observable<T> {
        return super.updateNotificationsToken(token);
    }

    public save(user: T): Observable<T> {
        return super.save(user);
    }
}
