import { Inject, Component, OnInit, ElementRef, ViewChild, AfterViewInit, ModuleWithComponentFactories, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogStateService, USER_STATE_SERVICE, CLIENT_ID, ApplicationStateService, NotificationStateService, UserFuncService, ApplicationContext } from '@noctem/web';
import { GameStateService } from '../../../../../noctem-lib/src/lib/state/game-state.service';
import { UserStateService } from '../../../../../noctem-lib/src/lib/state/user-state';
import { NetworkService } from '../../../../../noctem-lib/src/lib/services/network-service';
import { LogCacheService } from '../logs/log-cache-service';
import { TreatmentPlanStateService, TreatmentPhase } from '../../../../../noctem-lib/src/lib/state/treatment-plan-state.service';
import { UserAction, SleepPrescription, AssessmentStatus } from '../../../../../noctem-lib/src/lib/services/models';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { some as _some } from 'lodash';
import { DeviceService } from '../core/device-service';
import PullToRefresh from 'pulltorefreshjs';
import { Platform } from '@ionic/angular';
import { Badge } from '@ionic-native/badge/ngx';
import { PushNotificationSchema, Token, PushNotifications  } from '@capacitor/push-notifications';

import { FCM } from "@capacitor-community/fcm";
import moment from 'moment';
import { AppNativeBus, APP_NATIVE_MESSAGES } from '../app.bus';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: []
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy{
    @ViewChild('sliderDiv', {}) sliderDiv: ElementRef;

    public isModalOpen: boolean = false;
    public currentLogType: string;
    public currentLogLabel: string;
    public currentPatientPoints: number;
    public currentCoinName: string;
    public currentCoinPath: string;
    public currentCoinPathTails: string;
    public coinStyle: SafeStyle;
    public coinStyleTails: SafeStyle;
    public currentPanel = 0;
    public userActions: Array<UserAction>;
    public phases: Array<TreatmentPhase>;
    public currentPhase: TreatmentPhase;
    public displayPhases: Array<TreatmentPhase>;
    public message: string;
    public alertClass: string;
    public sleepPrescription: SleepPrescription;
    public hasActiveLog: boolean;
    public currentPageIndex: number;
    public isLoading: boolean;
    public pointsRemaining:string;
    public isSyncing:boolean = false;
    public isTreatmentActive: boolean;
    public treatmentStartDate;
    incompleteLogCount: number;
    logButtonInactive: boolean = false;
    destroy$: Subject<any> = new Subject();

    constructor(
        @Inject(USER_STATE_SERVICE) public userStateService: UserStateService,
        @Inject(CLIENT_ID) private clientId: string,
        public appStateService: ApplicationStateService,
        private logStateService: LogStateService,
        private router: Router,
        private gameStateService: GameStateService,
        private notificationService: NotificationStateService,
        public treatmentPlanStateService: TreatmentPlanStateService,
        private sanitizer: DomSanitizer,
        private deviceService: DeviceService,
        private userService: UserFuncService,
        private platform: Platform,
        private badge: Badge,
        public networkService: NetworkService,
        private logCacheService: LogCacheService,
        private applicationContext: ApplicationContext,
        private appNativeBus: AppNativeBus
    ) { }

    ngOnInit() {
        const storedPin = this.getStoredItem('pin');
        const storedToken = this.getStoredItem('token');
        if (!storedPin || !storedToken) {
            this.goToUrl("/login");
        }

        this.authenticateIfAlreadyLoggedIn().then(_ =>{
            this.initialize();
        });
        
        if(this.platform.is('mobileweb') || this.platform.is('desktop')) {
            console.log('Skipping notifications setup. Running in browser.');
        } else {
            this.setupNotifications();
        }

        this.logCacheService.onSync$.pipe(
            takeUntil(this.destroy$)
            ).subscribe(status =>{
            this.isSyncing = status;
        })

        this.appNativeBus.getMessages().pipe(
            takeUntil(this.destroy$)
            ).subscribe(e => { //Refresh dashboard after the app is 'reawaken' 
            if(e.key=== APP_NATIVE_MESSAGES.APP_ACTIVE){
                if(this.router.url==='/'){
                    //const randomPath = Math.round(Math.random()*100000); //up to 5 digit random numbers to refresh the cache
                    //location.href='/'+randomPath; //partial refresh wont fix 1126, force entire page reload
                    location.reload();
                }
            }  
        });
    }

    ngAfterViewInit():void{
        PullToRefresh.init({
            triggerElement:'.challengeCoinBar',
            shouldPullToRefresh: function(){
              return window.location.pathname=='/';
            },
          });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async authenticateIfAlreadyLoggedIn() {
        const storedPin = this.getStoredItem('pin');
        this.userStateService.initialize();
        if (this.networkService.hasInitialized() && this.networkService.isOnline()) {
            if(!this.applicationContext.User){ //to avoid double calls, check on whether the app context user is null first
                await this.userStateService.authenticate(storedPin, 'Password01', this.clientId, true);
                this.logCacheService.submitCachedLogs();
            }
            else{
                this.logCacheService.submitCachedLogs();
            }
        } else {
            this.networkService.onConnectionChange$.pipe(
                takeUntil(this.destroy$)
                ).subscribe(async() => {
                if (this.networkService.isOnline()) {
                    await this.userStateService.authenticate(storedPin, 'Password01', this.clientId, true);
                    this.logCacheService.submitCachedLogs();
                }
            })
        }
    }

    private getStoredItem(item) {
        return localStorage.getItem(item);
    }

    private refreshCoins(){
      const coinInfo = this.gameStateService.getCurrentCoinInfo();
      this.currentPatientPoints = coinInfo.patientPoints;
      this.pointsRemaining = coinInfo.pointsRemaining + ((coinInfo.pointsRemaining === 1) ? ' Point ' : ' Points ');
      this.currentCoinName = coinInfo.coinName;
      this.currentCoinPath = coinInfo.imagePath;
      this.currentCoinPathTails = coinInfo.imagePathTails;
      this.coinStyle = this.sanitizer.bypassSecurityTrustStyle(`url(${this.currentCoinPath})`);
      this.coinStyleTails = this.sanitizer.bypassSecurityTrustStyle(`url(${this.currentCoinPathTails})`);
    }

    async initialize() {
        this.currentLogType = this.logStateService.getLogTypeByTimeOfDay();
        await this.gameStateService.initialize(this.applicationContext.User);
        this.refreshCoins();

        this.logStateService.state$.pipe(
            takeUntil(this.destroy$)
            ).subscribe(logState => {
            this.hasActiveLog = _some(logState.currentWeekLogs, log => log.morningLog.logStatus === AssessmentStatus.ACTIVE.name || log.eveningLog.logStatus === AssessmentStatus.ACTIVE.name);
        });

        this.incompleteLogCount = this.logStateService.getIncompleteLogCount();

        // Use this.treatmentStartDate for display on the front end only
        this.treatmentStartDate = moment(this.applicationContext.User.treatmentStartDate);
        // Take the treatmentStartDate and convert to the users local time for comparison
        let treatmentStartDateCompare = moment(this.applicationContext.User.treatmentStartDate).subtract(moment().utcOffset(), 'minutes').startOf('day')
        // Today is in local time
        let today = moment();
        // Calculate if the user's treatment is active right now
        this.isTreatmentActive = today >= treatmentStartDateCompare;
        //console.log('this.treatmentStartDate: ', this.treatmentStartDate, treatmentStartDateCompare, today)

        if (this.isMorning() && this.hasActiveLog && this.isTreatmentActive) {
           this.currentLogLabel = 'Morning Log';
       } else if (!this.isMorning() && this.hasActiveLog && this.isTreatmentActive) {
           this.currentLogLabel = 'Evening Log'
       } else if (!this.hasActiveLog && this.incompleteLogCount > 0 && this.isTreatmentActive) {
           this.currentLogLabel = 'Complete Logs'
       } else {
           this.logButtonInactive = true;
           this.currentLogLabel = 'View Logs'
       }

        this.badge.hasPermission().then(() => {
        }).catch(() => {
            this.badge.requestPermission();
        })

        // TODO:  HACK
        setInterval(() => {
            if (this.sliderDiv) {
                this.currentPanel = Math.round(this.sliderDiv.nativeElement.scrollLeft / window.outerWidth);
            }
        }, 200);

        this.notificationService.state$.pipe(
            takeUntil(this.destroy$)
            ).subscribe(notificationstate => {
            this.appStateService.clearAlert("chat");
            notificationstate.notifications?.map(n => {
                if(!n.isRead && n.type === "newChatMessage")
                {
                    this.appStateService.addAlert("chat");
                }
            });
        });
        await this.treatmentPlanStateService.initialize();
        this.treatmentPlanStateService.state$.pipe(
            takeUntil(this.destroy$)
            ).subscribe(treatmentPlanState => {
            if (treatmentPlanState) {
                this.userActions = treatmentPlanState.currentUserActions || [];
                if (treatmentPlanState.phases) this.phases = treatmentPlanState.phases.filter(phase => phase.name !='checkin');
                //const today = new Date()
                //this.isTreatmentActive = today.toISOString() > this.applicationContext.User.treatmentStartDate;
                this.currentPhase = treatmentPlanState.currentPhase;
                this.displayPhases = getDisplayPhases(this.phases, this.currentPhase);
                this.message = treatmentPlanState.alertMessage;
                this.sleepPrescription = treatmentPlanState.currentSleepPrescription;
                this.isModalOpen = treatmentPlanState.isModalDisplayed;
            }

            if (this.currentPhase) {
                this.currentPageIndex = this.displayPhases.map(function(e) { return e.name; }).indexOf(this.currentPhase.name);
                this.scrollToPage(this.currentPageIndex);
            }
        });

        this.deviceService.getInfo().pipe(
            takeUntil(this.destroy$)
            ).subscribe(info => {
            this.userService.updateNotificationToken(info).pipe(
                takeUntil(this.destroy$)
            ).subscribe();
        });

        if (this.networkService.hasInitialized() && this.networkService.isOnline()) {
            this.requestUser()
        } else {
            this.networkService.onConnectionChange$.pipe(
                takeUntil(this.destroy$)
                ).subscribe(() => {
                if (this.networkService.isOnline()) {
                    this.requestUser();
                }
            })
        }
        // TODO: HACK
        // Runs incase LogDefinitions are not fully loaded the first time
        this.logStateService.updateWeekLogs();
    }

    private requestUser() {
        const user = this.userStateService.model.get().User;
        if (user) {
            user.timeZoneOffset = new Date().getTimezoneOffset();
            this.userService.updateUser(user.UserId, {
                timeZoneOffset: moment().utcOffset()
            }).pipe(takeUntil(this.destroy$)).subscribe(updateUser => {})
        }
    }

    //still unused for now, todo: use this to optimize app refresh process 
    /*
    refreshApp() {
        this.isLoading = true;
        this.treatmentPlanStateService.initialize().then(_ =>{
            this.initialize().then(_ =>{
                this.ngAfterViewInit();
                this.isLoading = false;
            })
        })
        
        setTimeout(() => {
            this.isLoading = false;
        }, 2000);

    }
    */

    isMorning() {
        return this.currentLogType === 'morningLog';
    }

    goToUrl(url: string) {
        this.router.navigateByUrl(url);
    }

    goToLogs() {
        // TODO: HACK
        // Runs incase LogDefinitions are not fully loaded the first time
        this.logStateService.updateWeekLogs();
        if (this.hasActiveLog && this.isTreatmentActive) {
            this.logStateService.setCurrentLog();
            this.goToUrl('/logs');
        } else {
            this.goToUrl('/logs/week');
        }


    }

    scrollToPage(pageNumber: number) {
        setTimeout(() => {
            if (this.sliderDiv) {
                let position = pageNumber ? pageNumber * window.outerWidth : 0
                this.sliderDiv.nativeElement.scrollLeft = position;
            }
        }, 0);

    }

    private setupNotifications() {
        
        PushNotifications.checkPermissions().then( result => {
          if (result.receive === 'granted') {
            PushNotifications.register().then(() => {
                FCM.subscribeTo({ topic: "all_devices" })
                console.debug('registered');
            })
          } else {
            console.debug('Failed to register for push notificaitons.')
            //throw new Error('User denied permissions');
          } 
        });

        PushNotifications.addListener('registration', (token: Token) => {
            FCM.getToken().then(fcmToken =>  {
                this.userStateService.updateNotificationToken(fcmToken.token).pipe(
                takeUntil(this.destroy$)
                ).subscribe();
            })
          }
        );

        PushNotifications.addListener('registrationError',
          (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          }
        );

        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            this.badge.increase(1);
          }
        );

        PushNotifications.addListener('pushNotificationActionPerformed',
          () => {
            this.badge.clear();
          }
        );
      }
}


function getDisplayPhases(phases: Array<TreatmentPhase>, currentPhase: TreatmentPhase) {
    if (currentPhase && phases) {
        const max = phases.length;
        if (currentPhase.order <= 4) {
            return phases.slice(0,6);
        } else if (currentPhase.order + 2 > max) {
            return phases.slice(max-6, max);
        } else {
            return phases.slice(currentPhase.order-4, currentPhase.order+2);
        }
    }
}
