import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DOCUMENT } from '@angular/common';
import { ApplicationStateService } from '@noctem/web';
import { App, AppState } from '@capacitor/app';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Gtag } from 'angular-gtag';
import { NetworkService } from '../../../../noctem-lib/src/lib/services/network-service';
import { AppNativeBus, APP_NATIVE_MESSAGES } from './app.bus';
import { PushNotifications } from '@capacitor/push-notifications';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy, OnInit {
  ptrinit:any ;
  private $destroy = new Subject();
  constructor(
    private meta: Meta,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private networkService: NetworkService,
    @Inject(DOCUMENT) private document: Document,
    public appStateService: ApplicationStateService,
    private appNativeBus: AppNativeBus,
    private gtag: Gtag,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.networkService.initialize();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      App.addListener('appStateChange', (state: AppState) => {
        if (state.isActive) { 
            this.appNativeBus.notify(APP_NATIVE_MESSAGES.APP_ACTIVE);
        }
        else{ 
            //App has become inactive, notify the rest of the app components 
            this.appNativeBus.notify(APP_NATIVE_MESSAGES.APP_INACTIVE);
        }
      });
    });

    this.router.events.pipe(
      takeUntil(this.$destroy)
    ).subscribe(event => {
      if(event instanceof NavigationEnd) {
        const lastSectionOfUrlIndex = event.urlAfterRedirects.lastIndexOf("/") + 1;
        const pageName = event.urlAfterRedirects.slice(lastSectionOfUrlIndex);
        this.gtag.pageview({ page_title: pageName})
      }
    });

    //request permissions for notifications
    console.debug('app component request notification permission');
    PushNotifications.requestPermissions();
  }

  ngOnInit(): void {
    // Prevent indexing
    this.meta.addTag({name: 'robots', content:'NOINDEX,NOFOLLOW'});
    
    if (this.isIphone() && this.iPhoneVersion() !== 'none' ) {
      this.document.body.classList.add('hasNotch');
    }

    this.document.addEventListener('resume', () => {
      //window.location.reload();
    });
  }

  iPhoneVersion() {
    const height = window.screen.height;
    const width = window.screen.width;
    if (width === 414 && height === 896) {
      return 'XSMax / XR / 11 / 11 Pro Max';
    } else if (width === 428 && height === 926) {
      return '12 Pro Max / 13 Pro Max / 14 Plus';
    } else if (width === 390 && height === 844) {
      return '12 / 12 Pro / 13 / 13 Pro / 14';
    } else if (width === 375 && height === 812) {
      return 'X / XS / 11 Pro / 12 Mini / 13 Mini';
    } else if (width === 430 && height === 932) {
      return '14 Pro Max';
    } else if (width === 393 && height === 852) {
      return '14 Pro';
    }
    return 'none';
  }

  isIphone() {
    return !!navigator.userAgent.match(/iPhone/i);
  }

  ngOnDestroy() {
    this.$destroy.complete();
    this.$destroy.unsubscribe();
  }
}
