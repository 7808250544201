import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserStateService } from '../../../../../../noctem-lib/src/lib/state/user-state';
import { USER_STATE_SERVICE, NoctemUser, Group } from '@noctem/web';
import { Inject } from '@angular/core';
import { ApplicationStateService } from '../../../../../../noctem-lib/src/lib/state/application-state.service';
import { isDevMode } from '@angular/core';
import { environment} from '../../../environments/environment'
import { filter } from 'rxjs/internal/operators/filter';
import { APPLICATION_ORGANIZATION } from '../../../../../../noctem-lib/src/constants/constants';
import { GroupService } from '../../../../../../noctem-lib/src/lib/services';

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: []
})
export class LeftMenuComponent implements OnInit {

    public userFirstname: string;
    public userLastname: string;
    public isProduction: boolean;
    public isResearch:boolean;
    public isConsentRequired:boolean;
    public buildHash: string;
    public appVersion: string;
    private group:Group;
    private userId: string;

    constructor(private router: Router,
      @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
      private applicationStateService: ApplicationStateService,
      private groupService:GroupService
      ) { }

    ngOnInit() {
      this.userStateService.state$.pipe(
        filter((userState: any) => userState.User)
      ).subscribe((userState) => {
        const user =  userState.User as any as NoctemUser;
        const group = user?.groups.find(g => g.id !== APPLICATION_ORGANIZATION._id);
        this.userId = user.UserId;
        const groupId = group?.id;
        this.groupService.getAllAsync().then(gs => {
          const grp = gs.find(g => g.__i.guid === groupId);
          this.isResearch = grp?.isResearch;
          this.isConsentRequired = grp?.isConsentRequired;
          this.group = grp;
        });

      });
      this.isProduction = !isDevMode();
      let user: any = this.userStateService.model.get().User;
      if (user) {
          this.userFirstname = user.profile.firstName;
          this.userLastname = user.profile.lastName;
      }

      this.buildHash = environment.buildHash;
      this.appVersion = environment.appVersion;
    }

    goToUrl(url: string) {
        this.router.navigateByUrl(url);
    }

    goToFitbit(){
        const url = environment.fitbitLink+this.userId; //remember to assign UserId as state
        window.open(url,'_system')
    }

    public async goToConsent() {

      if(this.isResearch || this.isConsentRequired)
      {
        this.goToUrl(`/info/consent/${this.group.consentId ?? 0}`);
      }
        
    }

    public async goToEula(){
      this.goToUrl(`/info/eula/${this.group.eulaId ?? 0}`);
    }

    logoutCurrentUser() {
        // Remove the user's local storage data. This only includes an auth token, a pin, if the user entered one, and data for localization, culture and translationsCache
        localStorage.clear();
        this.applicationStateService?.logout();
    }
}
