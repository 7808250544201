import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { preFieldSurvey } from '../questions/training-survey/pre-field-survey';
import { preFieldSurveyMedic } from '../questions/training-survey/pre-field-survey-medic';

export const preFieldMedic: AssetDefinition = {
  id: 100, //was 10, conflict with sleep-checkin
  name: 'pre-field-medic',
  label: 'Pre Field Medic',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [
    preFieldSurvey, preFieldSurveyMedic
  ],
  url: 'pre-field-medic'
};