import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { Routes, RouterModule } from '@angular/router';
import { NoctemLibModule } from '@noctem/web';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';
import { SleepFactsComponent } from './sleep-facts/sleep-facts.component';
import { SleepRecommendationComponent } from './sleep-recommendation/sleep-recommendation.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { ConsentPageComponent } from './consent-page/consent-page.component';
import { FormsModule } from '@angular/forms';
import { SleepRecommendationModalComponent } from './sleep-recommendation-modal/sleep-recommendation-modal.component';
import { SocketIoModule, Socket, SocketIoConfig } from 'ngx-socket-io';
import { LogCacheService } from '../logs/log-cache-service';

const routes: Routes = [
  {
    path: '',
    canActivate: [ /*LoggedInGuard */],
    children: [
        { path: '', component: DashboardComponent },
        { path: 'obtain-consent', component: ConsentPageComponent },
        { path: 'obtain-consent/:context', component: ConsentPageComponent },
        { path: 'obtain-consent/:context/:agreementId', component: ConsentPageComponent }
    ]
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardPanelComponent,
    SleepFactsComponent,
    SleepRecommendationComponent,
    LeftMenuComponent,
    ConsentPageComponent,
    SleepRecommendationModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NoctemLibModule, 
    FormsModule
  ],
  entryComponents: [
    SleepRecommendationModalComponent
  ],
  providers: [
    LogCacheService
  ]
})
export class DashboardModule { }
